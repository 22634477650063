import React, { Fragment, useEffect, useState, useContext } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeLinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { CALENDAR_DAILY, CALENDAR_WEEKLY } from 'utils/constants/queryParams';
import { Toggle,
  ToggleLabel,
  ToggleWrapper, } from 'shared/styles/buttons';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { BsCheck } from 'react-icons/bs';
import { FiClock } from 'react-icons/fi';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { AppContext, BranchItemsContext } from 'context';

import $, { event } from 'jquery';
import {
  CalendarContainer,
  StaticArrowUpDown
} from './styles';
import moment from 'moment';
import { DataContainer } from 'shared/styles/constants/tagsStyles';
import { useRef } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { PURPLE } from 'shared/styles/constants/colors';
import { useMemo } from 'react';
import { capitalize } from 'shared/methods';
const OPTAPLANNER_STATUS = {
  SOLVING: 'SOLVING',
  TERMINATED: 'TERMINATED',
};

let VISITS_ASSIGNED = 0;
let VISITS_PUBLISHED = 0;
let VISITS_UNASSIGNED = 0;
let VISITS_UNPUBLISHED = 0;
let TOTAL_VISITS = 0;

let VISITS_ASSIGNED_PLANNED = 0;
let VISITS_PUBLISHED_PLANNED = 0;
let VISITS_UNASSIGNED_PLANNED = 0;
let VISITS_UNPUBLISHED_PLANNED = 0;
let TOTAL_VISITS_PLANNED = 0;

const PlannedCalendar = ({
  resources = [],
  events = [],
  setShiftType,
  shiftType = 'resourceTimelineDay',
  values,
  setValues,
  eventClickHandler = () => { },
  resourceAreaHeaderContent,
  showProgressSection = false,
  optaplannerStatus = [{ state: '', tries: 0 }],
  visitsAssigned = 0,
  visitsUnassigned = 0,
  loadingOptaPlanner = false,
  timeStart = null,
  timeStatus = '',
  totalVisits = 0,
  getOptaplannerStatus = () => { },
  runningDate = '',
  calendarView = 'team',
  setCalendarView,
  editMode,
  isSplitMode = false,
  setChangeVisitDetails,
  showDeleteAllVisits,
  showPlannerVisitsFn,
  setChangeVisit,
  timeZone,
  employeeVisitData,
  employeeVisitDataPlanned,
  clinetVisitData,
  rotaShift,
  handleDownlaod,
  downloadDisable,
  setLoading,
  setAllowSchedulerCalls = true,
  useInput,
  setListView = false,
  eventsPlanned,
  employeeResource,
  employeeResourcePlanned,
  decreaseWidthSch,
  setDecreaseWidthSch,
  decreaseWidthPlan,
  setDecreaseWidthPlan,
  waitHoursBetweenVisit,
  waitHoursExcludeMoreThanTwo,
  waitHoursCapacity,
  waitHoursBetweenVisitPlanned,
  waitHoursExcludeMoreThanTwoPlanned,
  waitHoursCapacityPlanned,
  visitScreenData = {}
}) => {
  const { contextChoices } = useContext(AppContext);

  const titleRef = useRef();
  const calendarRef = useRef([]);

  const [eventsValue, setEventsValue] = useState([]);
  const [recourseValue, setRecourseValue] = useState();
  const [runName, setRunName] = useState()
  // const [assignedHours, setAssignedHours] = useState(0);

  // const [travelHours, setTravelHours] = useState(0);
  const [travelModeHours, setTravelModeHours] = useState({});
  // const [travelHoursClientToClient, setTravelHoursClientToClient] = useState(0);
  // const [travelHoursClientToHome, setTravelHoursClientToHome] = useState(0);
  // const [travelHoursClientToOffice, setTravelHoursClientToOffice] = useState(0);
  // const [travelHoursOfficeToClient, setTravelHoursOfficeToClient] = useState(0);
  // const [travelHoursHomeToClient, setTravelHoursHomeToClient] = useState(0);
  // const [travelHoursHomeToOffice, setTravelHoursHomeToOffice] = useState(0);
  // const [travelHoursOfficeToOffice, setTravelHoursOfficeToOffice] = useState(0);

 


  const [waitHours, setWaitHours] = useState(0);
  // const [unassignedHours, setUnassignedHours] = useState(0);
  // const [publishedHours, setPublishedHours] = useState(0);
  // const [unPublishedHours, setUnPublishedHours] = useState(0);
  // const [clientResource, setClientResource] = useState('')

  const [travelHoursClientToClientPlanned, setTravelHoursClientToClientPlanned] = useState(0);
  const [travelHoursClientToHomePlanned, setTravelHoursClientToHomePlanned] = useState(0);
  const [travelHoursClientToOfficePlanned, setTravelHoursClientToOfficePlanned] = useState(0);
  const [travelHoursOfficeToClientPlanned, setTravelHoursOfficeToClientPlanned] = useState(0);
  const [travelHoursHomeToClientPlanned, setTravelHoursHomeToClientPlanned] = useState(0);
  const [travelHoursHomeToOfficePlanned, setTravelHoursHomeToOfficePlanned] = useState(0);
  const [travelHoursOfficeToOfficePlanned, setTravelHoursOfficeToOfficePlanned] = useState(0);

//wait time counts
  // const [waitHoursBetweenVisit, setWaitHoursBetweenVisit] = useState(0);
  // const [waitHoursExcludeMoreThanTwo, setWaitHoursExcludeMoreThanTwo] = useState(0);
  // const [waitHoursCapacity, setWaitHoursCapacity] = useState(0);

  // const [waitHoursBetweenVisitPlanned, setWaitHoursBetweenVisitPlanned] = useState(0);
  // const [waitHoursExcludeMoreThanTwoPlanned, setWaitHoursExcludeMoreThanTwoPlanned] = useState(0);
  // const [waitHoursCapacityPlanned, setWaitHoursCapacityPlanned] = useState(0);

  const [assignedHoursPlanned, setAssignedHoursPlanned] = useState(0);
  const [travelHoursPlanned, setTravelHoursPlanned] = useState(0);
  const [travelModeHoursPlanned, setTravelModeHoursPlanned] = useState({});
  const [waitHoursPlanned, setWaitHoursPlanned] = useState(0);
  const [travelTotalPlannedShiftCounts, setTravelTotalPlannedShiftCounts] = useState(0);
  const [travelTotalSchShiftCounts, setTravelTotalSchShiftCounts] = useState(0);
  const [travelTotalPlannedVisitCounts, setTravelTotalPlannedVisitCounts] = useState(0);
  const [travelTotalSchVisitCounts, setTravelTotalSchVisitCounts] = useState(0);

  const [unassignedHoursPlanned, setUnassignedHoursPlanned] = useState(0);
  const [publishedHoursPlanned, setPublishedHoursPlanned] = useState(0);
  const [unPublishedHoursPlanned, setUnPublishedHoursPlanned] = useState(0);
  const [clientResourcePlanned, setClientResourcePlanned] = useState('')
  // const [employeeResourcePlanned, setEmployeeResourcePlanned] = useState('')
  // const {contextChoices, contextIsAdmin, contextIsSuperAdmin, contextIsStaff} =
  // useContext(AppContext);

  const travelPlannedShiftCounts = useMemo(()=> {
    let TravelCountsShift = {}
    let countsHrs = 0
    if (contextChoices.travel_type) {
      contextChoices.travel_type.forEach((val) => {
        let travelHoursCheckMode = eventsPlanned
          .filter(event => event && event.traveltime && !event.blank && event.shiftTravel && (event.travel_mode_name == val.name))
          .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

        if (travelHoursCheckMode.length > 0) {
          let aHours = travelHoursCheckMode.reduce((total, visit) => total + visit);
          if (aHours % 0.1 === 0) {
            TravelCountsShift[val.name] = aHours;
            countsHrs += aHours
          } else {
            TravelCountsShift[val.name] = Math.round(aHours * 100) / 100;
            countsHrs = countsHrs + (Math.round(aHours * 100) / 100)
          }
        } else {
          TravelCountsShift[val.name] = 0;
        }
      })
    }
    setTravelTotalPlannedShiftCounts(countsHrs)
    return TravelCountsShift || {}
},[contextChoices.travel_type, eventsPlanned])

const travelScheduledShiftCounts = useMemo(()=> {
  let TravelCountsShift = {}
  let countsHrs = 0
  if (contextChoices.travel_type) {
    contextChoices.travel_type.forEach((val) => {
      let travelHoursCheckMode = events
        .filter(event => event && event.traveltime && !event.blank && event.shiftTravel && (event.travel_mode_name == val.name))
        .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

      if (travelHoursCheckMode.length > 0) {
        let aHours = travelHoursCheckMode.reduce((total, visit) => total + visit);
        if (aHours % 0.1 === 0) {
          TravelCountsShift[val.name] = aHours;
          countsHrs += aHours
        } else {
          TravelCountsShift[val.name] = Math.round(aHours * 100) / 100;
          countsHrs = countsHrs + (Math.round(aHours * 100) / 100)
        }
      } else {
        TravelCountsShift[val.name] = 0;
      }
    })
  }
  setTravelTotalSchShiftCounts(countsHrs)
  return TravelCountsShift || {}
},[contextChoices.travel_type, events])

const travelSchHoursClientToClientShift = useMemo(()=> {
  // client to client
  let valuesTravel = 0
  let travelHoursCheck2 = events
  .filter(event => event && event.traveltime && !event.blank && event.shiftTravel && event.travel_code && event.travel_code === 'CLIENT-CLIENT')
  .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

if (travelHoursCheck2.length > 0) {
  let aHours = travelHoursCheck2.reduce((total, visit) => total + visit);
  aHours % 0.1 === 0
    ? valuesTravel = aHours
    : valuesTravel = Math.round(aHours * 100) / 100;
} else {
  valuesTravel = 0
}
  return valuesTravel || 0
},[contextChoices.travel_type, events])

const travelSchHoursClientToOfficeShift = useMemo(()=> {
// client to client
let valuesTravel = 0
let travelHoursCheck2 = events
.filter(event => event && event.traveltime && !event.blank && event.shiftTravel && event.travel_code && event.travel_code === 'CLIENT-OFFICE')
.map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

if (travelHoursCheck2.length > 0) {
let aHours = travelHoursCheck2.reduce((total, visit) => total + visit);
aHours % 0.1 === 0
  ? valuesTravel = aHours
  : valuesTravel = Math.round(aHours * 100) / 100;
} else {
valuesTravel = 0
}
return valuesTravel || 0
},[contextChoices.travel_type, events])

const travelSchHoursOfficeToClientShift = useMemo(()=> {
// client to client
let valuesTravel = 0
let travelHoursCheck2 = events
.filter(event => event && event.traveltime && !event.blank && event.shiftTravel && event.travel_code && event.travel_code === 'OFFICE-CLIENT')
.map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

if (travelHoursCheck2.length > 0) {
let aHours = travelHoursCheck2.reduce((total, visit) => total + visit);
aHours % 0.1 === 0
  ? valuesTravel = aHours
  : valuesTravel = Math.round(aHours * 100) / 100;
} else {
valuesTravel = 0
}
return valuesTravel || 0
},[contextChoices.travel_type, events])

const travelSchHoursClientToHomeShift = useMemo(()=> {
// client to client
let valuesTravel = 0
let travelHoursCheck2 = events
.filter(event => event && event.traveltime && !event.blank && event.shiftTravel && event.travel_code && event.travel_code === 'HOME-CLIENT')
.map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

if (travelHoursCheck2.length > 0) {
let aHours = travelHoursCheck2.reduce((total, visit) => total + visit);
aHours % 0.1 === 0
  ? valuesTravel = aHours
  : valuesTravel = Math.round(aHours * 100) / 100;
} else {
valuesTravel = 0
}
return valuesTravel || 0
},[contextChoices.travel_type, events])

const travelSchHoursHomeToClientShift = useMemo(()=> {
// client to client
let valuesTravel = 0
let travelHoursCheck2 = events
.filter(event => event && event.traveltime && !event.blank && event.shiftTravel && event.travel_code && event.travel_code === 'HOME-CLIENT')
.map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

if (travelHoursCheck2.length > 0) {
let aHours = travelHoursCheck2.reduce((total, visit) => total + visit);
aHours % 0.1 === 0
  ? valuesTravel = aHours
  : valuesTravel = Math.round(aHours * 100) / 100;
} else {
valuesTravel = 0
}
return valuesTravel || 0
},[contextChoices.travel_type, events])

const travelSchHoursHomeToOfficeShift = useMemo(()=> {
// client to client
let valuesTravel = 0
let travelHoursCheck2 = events
.filter(event => event && event.traveltime && !event.blank && event.shiftTravel && event.travel_code && event.travel_code === 'HOME-OFFICE')
.map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

if (travelHoursCheck2.length > 0) {
let aHours = travelHoursCheck2.reduce((total, visit) => total + visit);
aHours % 0.1 === 0
  ? valuesTravel = aHours
  : valuesTravel = Math.round(aHours * 100) / 100;
} else {
valuesTravel = 0
}
return valuesTravel || 0
},[contextChoices.travel_type, events])

const travelSchHoursOfficeToOfficeShift = useMemo(()=> {
// client to client
let valuesTravel = 0
let travelHoursCheck2 = events
.filter(event => event && event.traveltime && !event.blank && event.shiftTravel && event.travel_code && event.travel_code === 'OFFICE-OFFICE')
.map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

if (travelHoursCheck2.length > 0) {
let aHours = travelHoursCheck2.reduce((total, visit) => total + visit);
aHours % 0.1 === 0
  ? valuesTravel = aHours
  : valuesTravel = Math.round(aHours * 100) / 100;
} else {
valuesTravel = 0
}
return valuesTravel || 0
},[contextChoices.travel_type, events])


const travelPlannedHoursClientToClientShift = useMemo(()=> {
  // client to client
  let valuesTravel = 0
  let travelHoursCheck2 = eventsPlanned
  .filter(event => event && event.traveltime && !event.blank && event.shiftTravel && event.travel_code && event.travel_code === 'CLIENT-CLIENT')
  .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

if (travelHoursCheck2.length > 0) {
  let aHours = travelHoursCheck2.reduce((total, visit) => total + visit);
  aHours % 0.1 === 0
    ? valuesTravel = aHours
    : valuesTravel = Math.round(aHours * 100) / 100;
} else {
  valuesTravel = 0
}
  return valuesTravel || 0
},[contextChoices.travel_type, eventsPlanned])

const travelPlannedHoursClientToOfficeShift = useMemo(()=> {
// client to client
let valuesTravel = 0
let travelHoursCheck2 = eventsPlanned
.filter(event => event && event.traveltime && !event.blank && event.shiftTravel && event.travel_code && event.travel_code === 'CLIENT-OFFICE')
.map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

if (travelHoursCheck2.length > 0) {
let aHours = travelHoursCheck2.reduce((total, visit) => total + visit);
aHours % 0.1 === 0
  ? valuesTravel = aHours
  : valuesTravel = Math.round(aHours * 100) / 100;
} else {
valuesTravel = 0
}
return valuesTravel || 0
},[contextChoices.travel_type, eventsPlanned])

const travelPlannedHoursOfficeToClientShift = useMemo(()=> {
// client to client
let valuesTravel = 0
let travelHoursCheck2 = eventsPlanned
.filter(event => event && event.traveltime && !event.blank && event.shiftTravel && event.travel_code && event.travel_code === 'OFFICE-CLIENT')
.map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

if (travelHoursCheck2.length > 0) {
let aHours = travelHoursCheck2.reduce((total, visit) => total + visit);
aHours % 0.1 === 0
  ? valuesTravel = aHours
  : valuesTravel = Math.round(aHours * 100) / 100;
} else {
valuesTravel = 0
}
return valuesTravel || 0
},[contextChoices.travel_type, eventsPlanned])

const travelPlannedHoursClientToHomeShift = useMemo(()=> {
// client to client
let valuesTravel = 0
let travelHoursCheck2 = eventsPlanned
.filter(event => event && event.traveltime && !event.blank && event.shiftTravel && event.travel_code && event.travel_code === 'HOME-CLIENT')
.map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

if (travelHoursCheck2.length > 0) {
let aHours = travelHoursCheck2.reduce((total, visit) => total + visit);
aHours % 0.1 === 0
  ? valuesTravel = aHours
  : valuesTravel = Math.round(aHours * 100) / 100;
} else {
valuesTravel = 0
}
return valuesTravel || 0
},[contextChoices.travel_type, eventsPlanned])

const travelPlannedHoursHomeToClientShift = useMemo(()=> {
// client to client
let valuesTravel = 0
let travelHoursCheck2 = eventsPlanned
.filter(event => event && event.traveltime && !event.blank && event.shiftTravel && event.travel_code && event.travel_code === 'HOME-CLIENT')
.map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

if (travelHoursCheck2.length > 0) {
let aHours = travelHoursCheck2.reduce((total, visit) => total + visit);
aHours % 0.1 === 0
  ? valuesTravel = aHours
  : valuesTravel = Math.round(aHours * 100) / 100;
} else {
valuesTravel = 0
}
return valuesTravel || 0
},[contextChoices.travel_type, eventsPlanned])

const travelPlannedHoursHomeToOfficeShift = useMemo(()=> {
// client to client
let valuesTravel = 0
let travelHoursCheck2 = eventsPlanned
.filter(event => event && event.traveltime && !event.blank && event.shiftTravel && event.travel_code && event.travel_code === 'HOME-OFFICE')
.map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

if (travelHoursCheck2.length > 0) {
let aHours = travelHoursCheck2.reduce((total, visit) => total + visit);
aHours % 0.1 === 0
  ? valuesTravel = aHours
  : valuesTravel = Math.round(aHours * 100) / 100;
} else {
valuesTravel = 0
}
return valuesTravel || 0
},[contextChoices.travel_type, eventsPlanned])

const travelPlannedHoursOfficeToOfficeShift = useMemo(()=> {
// client to client
let valuesTravel = 0
let travelHoursCheck2 = eventsPlanned
.filter(event => event && event.traveltime && !event.blank && event.shiftTravel && event.travel_code && event.travel_code === 'OFFICE-OFFICE')
.map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

if (travelHoursCheck2.length > 0) {
let aHours = travelHoursCheck2.reduce((total, visit) => total + visit);
aHours % 0.1 === 0
  ? valuesTravel = aHours
  : valuesTravel = Math.round(aHours * 100) / 100;
} else {
valuesTravel = 0
}
return valuesTravel || 0
},[contextChoices.travel_type, eventsPlanned])
 

  useEffect(() => {
    if (events) {

      let uniquie_visit_id = []
      VISITS_ASSIGNED = 0
      VISITS_UNASSIGNED = 0
      VISITS_PUBLISHED = 0
      VISITS_UNPUBLISHED = 0
      events.map(detail => {
        if (!uniquie_visit_id.find(id => id === detail.id)) {
          uniquie_visit_id.push(detail.id)
          if (detail.unassigned != undefined) {
            if (!detail.unassigned) {
              VISITS_ASSIGNED += 1;
            } else {
              VISITS_UNASSIGNED += 1;
            }
            if (detail.is_published) {
              VISITS_PUBLISHED += 1
            } else {
              VISITS_UNPUBLISHED += 1
            }
          }
        }
      })
      // let unHoursCheck = events
      //   .filter(event => event && event.unassigned)
      //   .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

      // if (unHoursCheck.length > 0) {
      //   let unHours = unHoursCheck.reduce((total, visit) => total + visit);
      //   unHours % 0.1 === 0
      //     ? setUnassignedHours(unHours)
      //     : setUnassignedHours(Math.round(unHours * 100) / 100);
      // } else {
      //   setUnassignedHours(0);
      // }

      // let publishedHoursCheck = events
      //   .filter(event => event && event.visit && event.is_published)
      //   .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

      // if (publishedHoursCheck.length > 0) {
      //   let unHours = publishedHoursCheck.reduce((total, visit) => total + visit);
      //   unHours % 0.1 === 0
      //     ? setPublishedHours(unHours)
      //     : setPublishedHours(Math.round(unHours * 100) / 100);
      // } else {
      //   setPublishedHours(0);
      // }

      // let unPublishedHoursCheck = events
      //   .filter(event => event && event.visit && !event.is_published)
      //   .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

      // if (unPublishedHoursCheck.length > 0) {
      //   let unHours = unPublishedHoursCheck.reduce((total, visit) => total + visit);
      //   unHours % 0.1 === 0
      //     ? setUnPublishedHours(unHours)
      //     : setUnPublishedHours(Math.round(unHours * 100) / 100);
      // } else {
      //   setUnPublishedHours(0);
      // }

      // let aHoursCheck = events
      //   .filter(event => event && !event.unassigned && !event.traveltime && !event.waittime && !event.blank)
      //   .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

      // if (aHoursCheck.length > 0) {
      //   let aHours = aHoursCheck.reduce((total, visit) => total + visit);
      //   aHours % 0.1 === 0
      //     ? setAssignedHours(aHours)
      //     : setAssignedHours(Math.round(aHours * 100) / 100);
      // } else {
      //   setAssignedHours(0);
      // }

      // let tavel_code_array = []
      // if (evvSettingData.client_to_client) {
      //   tavel_code_array.push('CLIENT:CLIENT')
      // }
      // if (evvSettingData.client_to_home) {
      //   tavel_code_array.push('CLIENT:HOME')
      // }
      // if (evvSettingData.client_to_office) {
      //   tavel_code_array.push('CLIENT:OFFICE')
      // }
      // if (evvSettingData.office_to_client) {
      //   tavel_code_array.push('OFFICE:CLIENT')
      // }
      // if (evvSettingData.home_to_client) {
      //   tavel_code_array.push('HOME:CLIENT')
      // }
      // if (evvSettingData.home_to_office) {
      //   tavel_code_array.push('HOME:OFFICE')
      // }
      // if (evvSettingData.office_to_office) {
      //   tavel_code_array.push('OFFICE:OFFICE')
      // }
      let countsHrs = 0
      if (contextChoices.travel_type) {
        contextChoices.travel_type.forEach((val) => {
          let travelHoursCheckMode = events
            .filter(event => event && event.traveltime && !event.blank && event.visitTravel && (event.travel_mode_name == val.name))
            .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

          if (travelHoursCheckMode.length > 0) {
            let aHours = travelHoursCheckMode.reduce((total, visit) => total + visit);
            if (aHours % 0.1 === 0) {
              travelModeHours[val.name] = aHours;
              countsHrs += aHours
              setTravelModeHours({ ...travelModeHours })
            } else {
              travelModeHours[val.name] = Math.round(aHours * 100) / 100;
              countsHrs = countsHrs + (Math.round(aHours * 100) / 100)
              setTravelModeHours({ ...travelModeHours });
            }
          } else {
            travelModeHours[val.name] = 0;
            setTravelModeHours({ ...travelModeHours });
          }
        })
      }

      setTravelTotalSchVisitCounts(countsHrs)

      // && tavel_code_array.some((val) => val == event.travel_code)
      // let travelHoursCheck1 = events
      //   .filter(event => event && event.traveltime && !event.blank && event.travel_code)
      //   .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);



      // if (travelHoursCheck1.length > 0) {
      //   let aHours = travelHoursCheck1.reduce((total, visit) => total + visit);
      //   aHours % 0.1 === 0
      //     ? setTravelHours(aHours)
      //     : setTravelHours(Math.round(aHours * 100) / 100);
      // } else {
      //   setTravelHours(0);
      // }

      // client to client
      // let travelHoursCheck2 = events
      //   .filter(event => event && event.traveltime && !event.blank && event.travel_code && event.travel_code === 'CLIENT-CLIENT')
      //   .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

      // if (travelHoursCheck2.length > 0) {
      //   let aHours = travelHoursCheck2.reduce((total, visit) => total + visit);
      //   aHours % 0.1 === 0
      //     ? setTravelHoursClientToClient(aHours)
      //     : setTravelHoursClientToClient(Math.round(aHours * 100) / 100);
      // } else {
      //   setTravelHoursClientToClient(0);
      // }

      // client to home
      // let travelHoursCheck3 = events
      //   .filter(event => event && event.traveltime && !event.blank && event.travel_code && event.travel_code === 'CLIENT-HOME')
      //   .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

      // if (travelHoursCheck3.length > 0) {
      //   let aHours = travelHoursCheck3.reduce((total, visit) => total + visit);
      //   aHours % 0.1 === 0
      //     ? setTravelHoursClientToHome(aHours)
      //     : setTravelHoursClientToHome(Math.round(aHours * 100) / 100);
      // } else {
      //   setTravelHoursClientToHome(0);
      // }

      // client to office
      // let travelHoursCheck4 = events
      //   .filter(event => event && event.traveltime && !event.blank && event.travel_code && event.travel_code === 'CLIENT-OFFICE')
      //   .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

      // if (travelHoursCheck4.length > 0) {
      //   let aHours = travelHoursCheck4.reduce((total, visit) => total + visit);
      //   aHours % 0.1 === 0
      //     ? setTravelHoursClientToOffice(aHours)
      //     : setTravelHoursClientToOffice(Math.round(aHours * 100) / 100);
      // } else {
      //   setTravelHoursClientToOffice(0);
      // }

      //office to client
      // let travelHoursCheck5 = events
      //   .filter(event => event && event.traveltime && !event.blank && event.travel_code && event.travel_code === 'OFFICE-CLIENT')
      //   .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

      // if (travelHoursCheck5.length > 0) {
      //   let aHours = travelHoursCheck5.reduce((total, visit) => total + visit);
      //   aHours % 0.1 === 0
      //     ? setTravelHoursOfficeToClient(aHours)
      //     : setTravelHoursOfficeToClient(Math.round(aHours * 100) / 100);
      // } else {
      //   setTravelHoursOfficeToClient(0);
      // }

      //home to client 
      // let travelHoursCheck6 = events
      //   .filter(event => event && event.traveltime && !event.blank && event.travel_code && event.travel_code === 'HOME-CLIENT')
      //   .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

      // if (travelHoursCheck6.length > 0) {
      //   let aHours = travelHoursCheck6.reduce((total, visit) => total + visit);
      //   aHours % 0.1 === 0
      //     ? setTravelHoursHomeToClient(aHours)
      //     : setTravelHoursHomeToClient(Math.round(aHours * 100) / 100);
      // } else {
      //   setTravelHoursHomeToClient(0);
      // }

      //home to office
      // let travelHoursCheck7 = events
      //   .filter(event => event && event.traveltime && !event.blank && event.travel_code && event.travel_code === 'HOME-OFFICE')
      //   .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

      // if (travelHoursCheck7.length > 0) {
      //   let aHours = travelHoursCheck7.reduce((total, visit) => total + visit);
      //   aHours % 0.1 === 0
      //     ? setTravelHoursHomeToOffice(aHours)
      //     : setTravelHoursHomeToOffice(Math.round(aHours * 100) / 100);
      // } else {
      //   setTravelHoursHomeToOffice(0);
      // }

      //office to office
      // let travelHoursCheck8 = events
      //   .filter(event => event && event.traveltime && !event.blank && event.travel_code && event.travel_code === 'OFFICE-OFFICE')
      //   .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

      // if (travelHoursCheck8.length > 0) {
      //   let aHours = travelHoursCheck8.reduce((total, visit) => total + visit);
      //   aHours % 0.1 === 0
      //     ? setTravelHoursOfficeToOffice(aHours)
      //     : setTravelHoursOfficeToOffice(Math.round(aHours * 100) / 100);
      // } else {
      //   setTravelHoursOfficeToOffice(0);
      // }


      let waitHoursCheck = events
        .filter(event => event && event.waittime && !event.blank)
        .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);
      if (waitHoursCheck.length > 0) {
        let aHours = waitHoursCheck.reduce((total, visit) => total + visit);
        aHours % 0.1 === 0
          ? setWaitHours(aHours)
          : setWaitHours(Math.round(aHours * 100) / 100);
      } else {
        setWaitHours(0);
      }
    }
  }, [events, contextChoices.travel_type]);



  useEffect(() => {
    if (eventsPlanned) {

      let uniquie_visit_id = []
      VISITS_ASSIGNED_PLANNED = 0
      VISITS_UNASSIGNED_PLANNED = 0
      VISITS_PUBLISHED_PLANNED = 0
      VISITS_UNPUBLISHED_PLANNED = 0
      eventsPlanned.map(detail => {
        if (!uniquie_visit_id.find(id => id === detail.id)) {
          uniquie_visit_id.push(detail.id)
          if (detail.unassigned != undefined) {
            if (!detail.unassigned) {
              VISITS_ASSIGNED_PLANNED += 1;
            } else {
              VISITS_UNASSIGNED_PLANNED += 1;
            }
            if (detail.is_published) {
              VISITS_PUBLISHED_PLANNED += 1
            } else {
              VISITS_UNPUBLISHED_PLANNED += 1
            }
          }
        }
      })
      let unHoursCheck = eventsPlanned
        .filter(event => event && event.unassigned)
        .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

      if (unHoursCheck.length > 0) {
        let unHours = unHoursCheck.reduce((total, visit) => total + visit);
        unHours % 0.1 === 0
          ? setUnassignedHoursPlanned(unHours)
          : setUnassignedHoursPlanned(Math.round(unHours * 100) / 100);
      } else {
        setUnassignedHoursPlanned(0);
      }

      let publishedHoursCheck = eventsPlanned
        .filter(event => event && event.visit && event.is_published)
        .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

      if (publishedHoursCheck.length > 0) {
        let unHours = publishedHoursCheck.reduce((total, visit) => total + visit);
        unHours % 0.1 === 0
          ? setPublishedHoursPlanned(unHours)
          : setPublishedHoursPlanned(Math.round(unHours * 100) / 100);
      } else {
        setPublishedHoursPlanned(0);
      }

      let unPublishedHoursCheck = eventsPlanned
        .filter(event => event && event.visit && !event.is_published)
        .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

      if (unPublishedHoursCheck.length > 0) {
        let unHours = unPublishedHoursCheck.reduce((total, visit) => total + visit);
        unHours % 0.1 === 0
          ? setUnPublishedHoursPlanned(unHours)
          : setUnPublishedHoursPlanned(Math.round(unHours * 100) / 100);
      } else {
        setUnPublishedHoursPlanned(0);
      }

      let aHoursCheck = eventsPlanned
        .filter(event => event && !event.unassigned && !event.traveltime && !event.waittime && !event.blank)
        .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

      if (aHoursCheck.length > 0) {
        let aHours = aHoursCheck.reduce((total, visit) => total + visit);
        aHours % 0.1 === 0
          ? setAssignedHoursPlanned(aHours)
          : setAssignedHoursPlanned(Math.round(aHours * 100) / 100);
      } else {
        setAssignedHoursPlanned(0);
      }

      // let tavel_code_array = []
      // if (evvSettingData.client_to_client) {
      //   tavel_code_array.push('CLIENT:CLIENT')
      // }
      // if (evvSettingData.client_to_home) {
      //   tavel_code_array.push('CLIENT:HOME')
      // }
      // if (evvSettingData.client_to_office) {
      //   tavel_code_array.push('CLIENT:OFFICE')
      // }
      // if (evvSettingData.office_to_client) {
      //   tavel_code_array.push('OFFICE:CLIENT')
      // }
      // if (evvSettingData.home_to_client) {
      //   tavel_code_array.push('HOME:CLIENT')
      // }
      // if (evvSettingData.home_to_office) {
      //   tavel_code_array.push('HOME:OFFICE')
      // }
      // if (evvSettingData.office_to_office) {
      //   tavel_code_array.push('OFFICE:OFFICE')
      // }
      let countsHrs = 0
      if (contextChoices.travel_type) {
        contextChoices.travel_type.forEach((val) => {
          let travelHoursCheckMode = eventsPlanned
            .filter(event => event && event.traveltime && !event.blank && event.visitTravel &&  (event.travel_mode_name == val.name))
            .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

          if (travelHoursCheckMode.length > 0) {
            let aHours = travelHoursCheckMode.reduce((total, visit) => total + visit);
            if (aHours % 0.1 === 0) {
              travelModeHours[val.name] = aHours;
              countsHrs += aHours
              setTravelModeHoursPlanned({ ...travelModeHours })
            } else {
              travelModeHours[val.name] = Math.round(aHours * 100) / 100;
              countsHrs = countsHrs + (Math.round(aHours * 100) / 100)
              setTravelModeHoursPlanned({ ...travelModeHours });
            }
          } else {
            travelModeHours[val.name] = 0;
            setTravelModeHoursPlanned({ ...travelModeHours });
          }
        })
      }
      setTravelTotalPlannedVisitCounts(countsHrs)

      // && tavel_code_array.some((val) => val == event.travel_code)
      let travelHoursCheck1 = eventsPlanned
        .filter(event => event && event.traveltime && !event.blank && event.travel_code)
        .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);



      if (travelHoursCheck1.length > 0) {
        let aHours = travelHoursCheck1.reduce((total, visit) => total + visit);
        aHours % 0.1 === 0
          ? setTravelHoursPlanned(aHours)
          : setTravelHoursPlanned(Math.round(aHours * 100) / 100);
      } else {
        setTravelHoursPlanned(0);
      }

      // client to client
      let travelHoursCheck2 = eventsPlanned
        .filter(event => event && event.traveltime && !event.blank && event.travel_code && event.travel_code === 'CLIENT-CLIENT')
        .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

      if (travelHoursCheck2.length > 0) {
        let aHours = travelHoursCheck2.reduce((total, visit) => total + visit);
        aHours % 0.1 === 0
          ? setTravelHoursClientToClientPlanned(aHours)
          : setTravelHoursClientToClientPlanned(Math.round(aHours * 100) / 100);
      } else {
        setTravelHoursClientToClientPlanned(0);
      }

      // client to home
      let travelHoursCheck3 = eventsPlanned
        .filter(event => event && event.traveltime && !event.blank && event.travel_code && event.travel_code === 'CLIENT-HOME')
        .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

      if (travelHoursCheck3.length > 0) {
        let aHours = travelHoursCheck3.reduce((total, visit) => total + visit);
        aHours % 0.1 === 0
          ? setTravelHoursClientToHomePlanned(aHours)
          : setTravelHoursClientToHomePlanned(Math.round(aHours * 100) / 100);
      } else {
        setTravelHoursClientToHomePlanned(0);
      }

      // client to office
      let travelHoursCheck4 = eventsPlanned
        .filter(event => event && event.traveltime && !event.blank && event.travel_code && event.travel_code === 'CLIENT-OFFICE')
        .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

      if (travelHoursCheck4.length > 0) {
        let aHours = travelHoursCheck4.reduce((total, visit) => total + visit);
        aHours % 0.1 === 0
          ? setTravelHoursClientToOfficePlanned(aHours)
          : setTravelHoursClientToOfficePlanned(Math.round(aHours * 100) / 100);
      } else {
        setTravelHoursClientToOfficePlanned(0);
      }

      //office to client
      let travelHoursCheck5 = eventsPlanned
        .filter(event => event && event.traveltime && !event.blank && event.travel_code && event.travel_code === 'OFFICE-CLIENT')
        .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

      if (travelHoursCheck5.length > 0) {
        let aHours = travelHoursCheck5.reduce((total, visit) => total + visit);
        aHours % 0.1 === 0
          ? setTravelHoursOfficeToClientPlanned(aHours)
          : setTravelHoursOfficeToClientPlanned(Math.round(aHours * 100) / 100);
      } else {
        setTravelHoursOfficeToClientPlanned(0);
      }

      //home to client 
      let travelHoursCheck6 = eventsPlanned
        .filter(event => event && event.traveltime && !event.blank && event.travel_code && event.travel_code === 'HOME-CLIENT')
        .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

      if (travelHoursCheck6.length > 0) {
        let aHours = travelHoursCheck6.reduce((total, visit) => total + visit);
        aHours % 0.1 === 0
          ? setTravelHoursHomeToClientPlanned(aHours)
          : setTravelHoursHomeToClientPlanned(Math.round(aHours * 100) / 100);
      } else {
        setTravelHoursHomeToClientPlanned(0);
      }

      //home to office
      let travelHoursCheck7 = eventsPlanned
        .filter(event => event && event.traveltime && !event.blank && event.travel_code && event.travel_code === 'HOME-OFFICE')
        .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

      if (travelHoursCheck7.length > 0) {
        let aHours = travelHoursCheck7.reduce((total, visit) => total + visit);
        aHours % 0.1 === 0
          ? setTravelHoursHomeToOfficePlanned(aHours)
          : setTravelHoursHomeToOfficePlanned(Math.round(aHours * 100) / 100);
      } else {
        setTravelHoursHomeToOfficePlanned(0);
      }

      //office to office
      let travelHoursCheck8 = eventsPlanned
        .filter(event => event && event.traveltime && !event.blank && event.travel_code && event.travel_code === 'OFFICE-OFFICE')
        .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

      if (travelHoursCheck8.length > 0) {
        let aHours = travelHoursCheck8.reduce((total, visit) => total + visit);
        aHours % 0.1 === 0
          ? setTravelHoursOfficeToOfficePlanned(aHours)
          : setTravelHoursOfficeToOfficePlanned(Math.round(aHours * 100) / 100);
      } else {
        setTravelHoursOfficeToOfficePlanned(0);
      }

      // filter events with wait time true an event.blank is true (employee is not consistent between empData & (res_data + rota_data))
      // extract hours from each event into waitHoursCheck
      let waitHoursCheck = eventsPlanned
        .filter(event => event && event.waittime && !event.blank)
        .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);
      if (waitHoursCheck.length > 0) {
        let aHours = waitHoursCheck.reduce((total, visit) => total + visit);
        // check for floating point errors
        aHours % 0.1 === 0
          ? setWaitHoursPlanned(aHours)
          : setWaitHoursPlanned(Math.round(aHours * 100) / 100);
      } else {
        setWaitHoursPlanned(0);
      }
    }
  }, [eventsPlanned, contextChoices.travel_type]);


  useEffect(() => {
    setEventsValue(events);
  }, [events])

  useEffect(() => {
    setRecourseValue(recourseValue);
    if (values.start_date && values.end_date) {
      if (!calendarRef.current) {
        return;
      }
      let calendarApi = calendarRef?.current[0]?.getApi();
      let calendarApi1 = calendarRef?.current[1]?.getApi();
      // const view = calendarApi.view;
      if(calendarRef && calendarRef.current[0]){
        calendarRef.current[0].getApi().gotoDate(values.start_date);
        calendarApi.changeView(shiftType);

      }
      if(calendarRef && calendarRef.current[1]){
        calendarRef.current[1].getApi().gotoDate(values.start_date);
        calendarApi1.changeView(shiftType);

            }
     
      
      // if(rotaShift){
      //   setShiftType(view.type);
      // }else{
      setShiftType(shiftType);
    }
    //}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.start_date, values.end_date]);

  function startOfWeek(date) {
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);

    return new Date(date.setDate(diff));

  }

  useEffect(() => {
    if (values.choose_date) {
      if (shiftType === CALENDAR_DAILY) {
        calendarRef.current[0].getApi().gotoDate(values.choose_date);
        calendarRef && calendarRef.current[1] && calendarRef.current[1].getApi().gotoDate(values.choose_date);
        values.start_date = moment.utc(values.choose_date).local().toDate();
        values.end_date = moment.utc(values.choose_date).add(1, 'days').local().toDate();
        setValues({ ...values });
      } else {
        calendarRef.current[0].getApi().gotoDate(values.choose_date);
        calendarRef && calendarRef.current[1] && calendarRef.current[1].getApi().gotoDate(values.choose_date); const c_date = new Date(values.choose_date)
        const str = startOfWeek(c_date);
        values.start_date = moment.utc(str).local().toDate();
        values.end_date = moment.utc(str).add(7, 'days').local().toDate();
        setValues({ ...values });
      }
    }
  }, [values.choose_date, shiftType])


  useEffect(() => {
    let _events = [...events];
    _events.forEach(e => {
      // e.start = moment(e.start).format()
      // e.end = moment(e.end).format()

      if (e && e.title && typeof e.title === 'object') {
        if (e.title?.length === 0) {
          e.title = '';
        } else e.title = e.title.find(title => title !== undefined);
      }
    });
    setEventsValue([..._events]);
    let index = 0;
    let resourceElement = null;
    let dataElement = null;
    document.querySelectorAll(".App .fc-scroller-harness-liquid").forEach(element => {
      if (index == 0) {
        resourceElement = element;
      } else {
        dataElement = element;
      }
      index++;
    });

    let headerData = null;
    index = 0;
    document.querySelectorAll(".App .fc-scroller").forEach(element => {
      if (index == 1)
        headerData = element;


      index++;
    });
    $(dataElement).scrollLeft($(headerData).scrollLeft());

    if (dataElement != null && resourceElement != null && headerData != null) {
      $(dataElement).scroll(function () {
        $(resourceElement).scrollTop($(dataElement).scrollTop());
        $(headerData).scrollLeft($(dataElement).scrollLeft());
      });
      $(resourceElement).attr("style", "overflow:hidden !important; overflow-x: scroll !important;");
    }

    //    document.querySelectorAll(".App .fc-media-screen").forEach(element=>{
    //    
    //      console.log("fc-media-screen:", $(element));
    //      $(element).css({'height':'100vh !important'})
    ////      $(element).height();
    //      
    //      
    //    });


    let index1 = 0;
    let resourceElement1 = null;
    let dataElement1 = null;
    document.querySelectorAll(".App2 .fc-scroller-harness-liquid").forEach(element => {
      if (index1 == 0) {
        resourceElement1 = element;
      } else {
        dataElement1 = element;
      }
      index1++;
    });

    let headerData1 = null;
    index1 = 0;
    document.querySelectorAll(".App2 .fc-scroller").forEach(element => {
      if (index1 == 1)
        headerData1 = element;


      index1++;
    });
    $(dataElement1).scrollLeft($(headerData1).scrollLeft());

    if (dataElement1 != null && resourceElement1 != null && headerData1 != null) {
      $(dataElement1).scroll(function () {
        $(resourceElement1).scrollTop($(dataElement1).scrollTop());
        $(headerData1).scrollLeft($(dataElement1).scrollLeft());
      });
      $(resourceElement1).attr("style", "overflow:hidden !important; overflow-x: scroll !important;");
    }

    let head_ele = document.querySelectorAll('.fc-header-toolbar');

    for (let i = 0; i < head_ele.length; i++) {
      head_ele[i].style.display = "none";
    }

    //  head_ele && head_ele.map((val)=>{
    //   val.style.display = 'none';
    //  })
  }, [events]);

  const handleDateClick = arg => {
    // console.log('handleDateClick -> arg', arg);
    // console.log('handleDateClick -> arg.dateStr', arg.dateStr);
  };

  const handleEventClick = arg => {
    if (arg.event._def.extendedProps.openModal) {
      return
    }
    eventClickHandler({
      id: arg.event.id,
      groupId: arg.event.groupId,
      extendedProps: arg.event.extendedProps,
    });

  };
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#6E6BFA',
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#6E6BFA"
    },
  }));
  const getCalendarTitle = () => {
    if (!calendarRef.current[0]) {
      return '';
    }
    let calendarApi = calendarRef.current[0].getApi();
    const view = calendarApi.view;
    return view.title;
  };


  const viewClassNames = () => {
    window.dispatchEvent(new Event('resize'));
  };

  const handleVisitChange = e => {
    console.log(e, "478555")
    let visit = {
      end_date: moment(e.event._instance.range.end),
      id: e.event._def.publicId,
      employee: null,
      start_date: moment(e.event._instance.range.start),
    };

    setChangeVisit(e);
    setChangeVisitDetails(visit);
    remove(e)
  };

  const handleClickPlan = () => {
    setDecreaseWidthPlan(!decreaseWidthPlan);
    if(decreaseWidthSch){
      setDecreaseWidthSch(false)
    }
    // setTimeout(() => {
    //   titleRef.current.lastElementChild.scrollIntoView({ behavior: 'smooth' });
    // }, 1000);
    let dataElement1 = null;
    if (decreaseWidthPlan) {
      document
        .querySelectorAll('.App .fc .fc-toolbar.fc-header-toolbar')
        .forEach(element => {
          dataElement1 = element;
        });
      if (dataElement1 != null) {
        $(dataElement1).attr(
          'style',
          'display:none !important; margin-top: -25px; margin-left: 10px'
        );
      }
    } else {
      document
        .querySelectorAll('.App .fc .fc-toolbar.fc-header-toolbar')
        .forEach(element => {
          dataElement1 = element;
        });
      if (dataElement1 != null) {
        $(dataElement1).attr(
          'style',
          'display:block !important; margin-top: -25px; margin-left: 10px; margin-bottom: 1.5rem'
        );
      }
    }
  };
  const handleClickSch = () => {
    setDecreaseWidthSch(!decreaseWidthSch);
    if(decreaseWidthPlan){
      setDecreaseWidthPlan(false)
    }
    // setTimeout(() => {
    //   titleRef.current.lastElementChild.scrollIntoView({ behavior: 'smooth' });
    // }, 1000);
    let dataElement1 = null;
    if (decreaseWidthSch) {
      document
        .querySelectorAll('.App .fc .fc-toolbar.fc-header-toolbar')
        .forEach(element => {
          dataElement1 = element;
        });
      if (dataElement1 != null) {
        $(dataElement1).attr(
          'style',
          'display:none !important; margin-top: -25px; margin-left: 10px'
        );
      }
    } else {
      document
        .querySelectorAll('.App .fc .fc-toolbar.fc-header-toolbar')
        .forEach(element => {
          dataElement1 = element;
        });
      if (dataElement1 != null) {
        $(dataElement1).attr(
          'style',
          'display:block !important; margin-top: -25px; margin-left: 10px; margin-bottom: 1.5rem'
        );
      }
    }
  };


  const remove = ({ event }) => {
    if (!calendarRef.current[0]) return;
    //if (inElement({ x: jsEvent.pageX, y: jsEvent.pageY }, calendarRef.current)) return;
    event.remove();
  };

  const [compactViewToggle, setCompactViewToggle] = useState(true);
  const compactEventItemStyle = compactViewToggle ? {
    height: "2.5rem"
  }: {};
  const compactEventItemTitleStyle = compactViewToggle ? {
    display: "flex"
  }: {}

  // console.log({eventsPlanned,eventsValue,visitScreenData},"eventsValue")

  return (
    <Fragment>
      {!decreaseWidthPlan && !decreaseWidthSch &&
      <DataContainer className="mb-4 mt-1 justify-content-between">
       <div style={{alignSelf:'flex-end'}}> 
       <h3 className=''>{getCalendarTitle()}</h3>
       <div className='mt-2 d-flex align-items-center'>
       <ToggleWrapper>
          <Toggle
            id="checkbox4"
            type="checkbox"
            checked={compactViewToggle}
            onClick={() => {
              setCompactViewToggle(!compactViewToggle)
            }}
          />
        <ToggleLabel htmlFor="checkbox4" />
          </ToggleWrapper>
          <span className="toggle-label ms-2 me-2">
            Compact View
          </span>
       </div>
       </div>

        <div className='d-flex'>
          <div className={`data-container data-container-schedule mt-1 me-2`} style={{flexDirection:'column',padding:'0px 0px 8px 0px'}}>
          <div style={{textAlign:'center', backgroundColor:'lightgrey', borderRadius:'1.875rem 1.875rem 0rem 0rem'}}>
          <p className="fontBold">Planned</p>
          </div>
           <div className='d-flex px-4 pt-1'> 
           {calendarView === 'client' &&
              <div className="data me-2">
                <p className="fontBold">Clients</p>
                <div className="contents">
                  <div className="sub-contents">
                    <p>Count</p>
                    <p>{clientResourcePlanned}</p>
                  </div>
                </div>
              </div>
            }
            {calendarView === 'team' &&
              <div className="data me-2">
                <p className="fontBold">Employees</p>
                <div className="contents">
                  <div className="sub-contents">
                    <p>Count</p>
                    <p>{employeeResourcePlanned || 0}</p>
                  </div>
                </div>
              </div>
            }
            <div className="data">
              <p className="fontBold">Assigned</p>
              <div className="contents">
                <div className="sub-contents me-2">
                  <p>Hours</p>
                  <p>{assignedHoursPlanned}</p>
                </div>
                <div className="sub-contents">
                  <p>Visits</p>
                  <p>{VISITS_ASSIGNED_PLANNED}</p>
                </div>
              </div>
            </div>
            <div className="data leftPadding">
              <p className="fontBold">Unassigned</p>
              <div className="contents">
                <div className="sub-contents me-2">
                  <p>Hours</p>
                  <p>{unassignedHoursPlanned}</p>
                </div>
                <div className="sub-contents">
                  <p>Visits</p>
                  <p>{VISITS_UNASSIGNED_PLANNED}</p>
                </div>
              </div>
            </div>
            {calendarView === 'team' &&
              <>
                <BootstrapTooltip title={
                      <>
                        <div className='d-flex'>
                          <div style={{width: '50%', marginRight:'5px'}}>
                            <h6>Visits</h6><span>({travelTotalPlannedVisitCounts})</span>
                            <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Client To Client: '}</span>
                      {travelHoursClientToClientPlanned}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Client To Home: '}</span>
                      {travelHoursClientToHomePlanned}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Client To Office: '}</span>
                      {travelHoursClientToOfficePlanned}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Office To Client: '}</span>
                      {travelHoursOfficeToClientPlanned}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Home To Client: '}</span>
                      {travelHoursHomeToClientPlanned}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Home To Office: '}</span>
                      {travelHoursHomeToOfficePlanned}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Office To Office: '}</span>
                      {travelHoursOfficeToOfficePlanned}
                    </div>
                  </div>
                  {Object.keys(travelModeHoursPlanned).map((item, i) => (
                          <div style={{ display: "flex" }}>
                            <div ><span style={{ fontWeight: "bold" }}>{`${item}: `}</span>
                              {travelModeHoursPlanned[item]}
                            </div>
                          </div>
                        ))}
                          </div>
                          <div style={{width: '50%'}}>
                          <h6>Shifts</h6><span>({travelTotalPlannedShiftCounts})</span>
                          <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Client To Client: '}</span>
                      {travelPlannedHoursClientToClientShift}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Client To Home: '}</span>
                      {travelPlannedHoursClientToHomeShift}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Client To Office: '}</span>
                      {travelPlannedHoursClientToOfficeShift}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Office To Client: '}</span>
                      {travelPlannedHoursOfficeToClientShift}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Home To Client: '}</span>
                      {travelPlannedHoursHomeToClientShift}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Home To Office: '}</span>
                      {travelPlannedHoursHomeToOfficeShift}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Office To Office: '}</span>
                      {travelPlannedHoursOfficeToOfficeShift}
                    </div>
                  </div>
                  {Object.keys(travelPlannedShiftCounts).map((item, i) => (
                          <div style={{ display: "flex" }}>
                            <div ><span style={{ fontWeight: "bold" }}>{`${item}: `}</span>
                              {travelPlannedShiftCounts[item]}
                            </div>
                          </div>
                        ))}
                          </div>
                        </div>
                      </>
                    }>
                        <div className="data leftPadding">
                          <p className="fontBold">Travel</p>
                          <div className="contents">
                            <div className="sub-contents me-2">
                              <p>Hours</p>
                              <p>{travelHoursPlanned}</p>
                            </div>
                          </div>
                        </div>
                      </BootstrapTooltip>
                {/* <div className="data leftPadding">
        <p className="fontBold">Wait</p>
        <div className="contents">
          <div className="sub-contents me-2">
            <p>Hours</p>
            <p>{waitHours}</p>
          </div>
        </div>
      </div> */}
                <BootstrapTooltip title={<>
                  <div style={{ display: "flex" }}>
                          <div ><span style={{ fontWeight: "bold" }}>{'Wait Hrs before first and after last visit (Capacity): '}</span>
                          {waitHoursCapacityPlanned && (waitHoursCapacityPlanned / 3600000).toFixed(2)}
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div ><span style={{ fontWeight: "bold" }}>{'Wait Hrs Between Visit: '}</span>
                          {waitHoursBetweenVisitPlanned && (waitHoursBetweenVisitPlanned / 3600000).toFixed(2)}
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div ><span style={{ fontWeight: "bold" }}>{'Wait Hrs Exclude More Than Two: '}</span>
                          {waitHoursExcludeMoreThanTwoPlanned && (waitHoursExcludeMoreThanTwoPlanned / 3600000).toFixed(2)}
                          </div>
                        </div>
                  {/* <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Wait Hrs Between Visit: '}</span>
                      {waitHoursBetweenVisitPlanned}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Wait Hrs Exclude More Than Two: '}</span>
                      {waitHoursExcludeMoreThanTwoPlanned}
                    </div>
                  </div> */}
                </>}>
                  <div className="data leftPadding">
                    <p className="fontBold">Wait</p>
                    <div className="contents">
                      <div className="sub-contents me-2">
                        <p>Hours</p>
                        <p>{waitHoursPlanned}</p>
                      </div>
                    </div>
                  </div>
                </BootstrapTooltip>

                {/* <div className="data leftPadding">
  <p className="fontBold">Travel</p>
  <div className="contents">
    <div className="sub-contents me-2">
      <p>Hours</p>
      <p>{travelHoursPlanned}</p>
    </div>
  </div>
</div>

<div className="data leftPadding">
  <p className="fontBold">Wait</p>
  <div className="contents">
    <div className="sub-contents me-2">
      <p>Hours</p>
      <p>{waitHoursPlanned}</p>
    </div>
  </div>
</div> */}

              </>
            }
           </div>

          </div>
            <div className={`data-container data-container-schedule mt-1 me-2`} style={{ flexDirection: 'column', padding: '0px 0px 8px 0px' }}>
              <div style={{ textAlign: 'center', backgroundColor: 'lightgrey', borderRadius: '1.875rem 1.875rem 0rem 0rem' }}>
                <p className="fontBold">Scheduled</p>
              </div>
              <div className='d-flex px-4 pt-1'>
                {calendarView === 'client' &&
                  <div className="data me-2">
                    <p className="fontBold">Clients</p>
                    <div className="contents">
                      <div className="sub-contents">
                        <p>Count</p>
                        <p>{visitScreenData?.totalClient}</p>
                      </div>
                    </div>
                  </div>
                }
                {calendarView === 'team' &&
                  <div className="data me-2">
                    <p className="fontBold">Employees</p>
                    <div className="contents">
                      <div className="sub-contents">
                        <p>Count</p>
                        <p>{visitScreenData?.totalEmployee}</p>
                      </div>
                    </div>
                  </div>
                }
                <div className="data">
                  <p className="fontBold">Assigned</p>
                  <div className="contents">
                    <div className="sub-contents me-2">
                      <p>Hours</p>
                      {calendarView === 'client' ?
                        <p>{visitScreenData?.clinetData?.assignedHours}</p> :
                        calendarView === 'location' ?
                          <p>{visitScreenData?.locationData?.assignedHours}</p> :
                          <p>{visitScreenData?.totalAssignedHours}</p>
                      }
                    </div>
                    <div className="sub-contents">
                      <p>Visits</p>
                      {calendarView === 'client' ?
                        <p>{visitScreenData?.clinetData?.assignedVisits}</p> :
                        calendarView === 'location' ?
                          <p>{visitScreenData?.locationData?.assignedVisits}</p> :
                          <p>{visitScreenData?.totalAssignedVisits}</p>
                      }
                    </div>
                  </div>
                </div>
                <div className="data leftPadding">
                  <p className="fontBold">Unassigned</p>
                  <div className="contents">
                    <div className="sub-contents me-2">
                      <p>Hours</p>
                      {calendarView === 'client' ?
                        <p>{visitScreenData?.clinetData?.unassignedHours}</p> :
                        calendarView === 'location' ?
                          <p>{visitScreenData?.locationData?.unassignedHours}</p> :
                          <p>{visitScreenData?.totalUnassignedHours}</p>
                      }
                    </div>
                    <div className="sub-contents">
                      <p>Visits</p>
                      {calendarView === 'client' ?
                        <p>{visitScreenData?.clinetData?.unassignedVisits}</p> :
                        calendarView === 'location' ?
                          <p>{visitScreenData?.locationData?.unassignedVisits}</p> :
                          <p>{visitScreenData?.totalUnassignedVisits}</p>
                      }
                    </div>
                  </div>
                </div>
                {calendarView === 'team' &&
                  <>
                    <did className="travel-tooltrip leftPadding">
                      <BootstrapTooltip class title={
                        <>
                          <div className='d-flex'>
                            <div style={{ width: '50%', marginRight: '5px' }}>
                              <h6>Client Visits</h6>
                              {/* Travel Code */}
                              {visitScreenData?.clientTravelCode && Object?.keys(visitScreenData?.clientTravelCode)?.map(item => {
                                return (
                                  <div style={{ display: "flex" }}>
                                    <div ><span style={{ fontWeight: "bold" }}>{`${capitalize(item)}: `}</span>
                                      {visitScreenData.clientTravelCode[item]}
                                    </div>
                                  </div>
                                )
                              })
                              }
                              {/* Travel Mode */}
                              {visitScreenData?.clientTravelMode && Object.keys(visitScreenData?.clientTravelMode).map((item, i) => (
                                <div style={{ display: "flex" }}>
                                  <div ><span style={{ fontWeight: "bold" }}>{`${capitalize(item)}: `}</span>
                                    {visitScreenData?.clientTravelMode[item]}
                                  </div>
                                </div>
                              ))}
                            </div>
                            {
                            <div style={{ width: '50%', marginRight: '5px' }}>
                              <h6>Location Visits</h6>
                              {/* Travel Code */}
                              {visitScreenData?.locationTravelCode && Object.keys(visitScreenData?.locationTravelCode).map((item, i) => {
                                return (
                                  <div style={{ display: "flex" }}>
                                    <div >
                                      <span style={{ fontWeight: "bold" }}>{`${capitalize(item)}: `}</span>
                                      {visitScreenData?.locationTravelCode[item]}
                                    </div>
                                  </div>)
                              })}


                              {/* Travel Mode */}
                              {visitScreenData?.locationTravelMode && Object.keys(visitScreenData?.locationTravelMode).map((item, i) => (
                                <div style={{ display: "flex" }}>
                                  <div>
                                    <span style={{ fontWeight: "bold" }}>{`${capitalize(item)}: `}</span>
                                    {visitScreenData?.locationTravelMode[item]}
                                  </div>
                                </div>
                              ))}
                            </div>
                            }

                            <div style={{ width: '50%' }}>
                              <h6>Shifts</h6>
                              {/* Travel Code */}
                              {visitScreenData?.shiftTravelCode && Object.keys(visitScreenData?.shiftTravelCode).map(item => {
                                return (
                                  <div style={{ display: "flex" }}>
                                    <div ><span style={{ fontWeight: "bold" }}>{`${capitalize(item)}: `}</span>
                                      {visitScreenData?.shiftTravelCode[item]}
                                    </div>
                                  </div>
                                )
                              })}


                              {/* Travel Mode */}
                              {visitScreenData?.shiftTravelMode && Object.keys(visitScreenData?.shiftTravelMode).map((item, i) => (
                                <div style={{ display: "flex" }}>
                                  <div ><span style={{ fontWeight: "bold" }}>{`${capitalize(item)}: `}</span>
                                    {visitScreenData?.shiftTravelMode[item]}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      }>
                        <div className="data leftPadding">
                          <p className="fontBold">Travel</p>
                          <div className="contents">
                            <div className="sub-contents me-2">
                              <p>Hours</p>
                              <p>{visitScreenData?.totalTravelHours}</p>
                            </div>
                          </div>
                        </div>
                      </BootstrapTooltip>
                    </did>
                    {/* <BootstrapTooltip title={
                      <>
                        <div className='d-flex'>
                          <div style={{width: '50%', marginRight:'5px'}}>
                            <h6>Visits</h6><span>({travelTotalSchVisitCounts})</span>
                            <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Client To Client: '}</span>
                      {travelHoursClientToClient}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Client To Home: '}</span>
                      {travelHoursClientToHome}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Client To Office: '}</span>
                      {travelHoursClientToOffice}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Office To Client: '}</span>
                      {travelHoursOfficeToClient}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Home To Client: '}</span>
                      {travelHoursHomeToClient}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Home To Office: '}</span>
                      {travelHoursHomeToOffice}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Office To Office: '}</span>
                      {travelHoursOfficeToOffice}
                    </div>
                  </div>
                  {Object.keys(travelModeHours).map((item, i) => (
                          <div style={{ display: "flex" }}>
                            <div ><span style={{ fontWeight: "bold" }}>{`${item}: `}</span>
                              {travelModeHours[item]}
                            </div>
                          </div>
                        ))}
                          </div>
                          <div style={{width: '50%'}}>
                          <h6>Shifts</h6><span>({travelTotalSchShiftCounts})</span>
                          <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Client To Client: '}</span>
                      {travelSchHoursClientToClientShift}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Client To Home: '}</span>
                      {travelSchHoursClientToHomeShift}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Client To Office: '}</span>
                      {travelSchHoursClientToOfficeShift}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Office To Client: '}</span>
                      {travelSchHoursOfficeToClientShift}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Home To Client: '}</span>
                      {travelSchHoursHomeToClientShift}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Home To Office: '}</span>
                      {travelSchHoursHomeToOfficeShift}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Office To Office: '}</span>
                      {travelSchHoursOfficeToOfficeShift}
                    </div>
                  </div>
                  {Object.keys(travelScheduledShiftCounts).map((item, i) => (
                          <div style={{ display: "flex" }}>
                            <div ><span style={{ fontWeight: "bold" }}>{`${item}: `}</span>
                              {travelScheduledShiftCounts[item]}
                            </div>
                          </div>
                        ))}
                          </div>
                        </div>
                      </>
                    }>
                        <div className="data leftPadding">
                          <p className="fontBold">Travel</p>
                          <div className="contents">
                            <div className="sub-contents me-2">
                              <p>Hours</p>
                              <p>{travelHours}</p>
                            </div>
                          </div>
                        </div>
                      </BootstrapTooltip> */}
                    <BootstrapTooltip title={<>
                      <div style={{ display: "flex" }}>
                        <div ><span style={{ fontWeight: "bold" }}>{'Wait Hrs before first and after last visit (Capacity): '}</span>
                          {waitHoursCapacity && (waitHoursCapacity / 3600000).toFixed(2)}
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div ><span style={{ fontWeight: "bold" }}>{'Wait Hrs Between Visit: '}</span>
                          {waitHoursBetweenVisit && (waitHoursBetweenVisit / 3600000).toFixed(2)}
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div ><span style={{ fontWeight: "bold" }}>{'Wait Hrs Exclude More Than Two: '}</span>
                          {waitHoursExcludeMoreThanTwo && (waitHoursExcludeMoreThanTwo / 3600000).toFixed(2)}
                        </div>
                      </div>
                      {/* <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Wait Hrs Between Visit: '}</span>
                      {waitHoursBetweenVisit}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div ><span style={{ fontWeight: "bold" }}>{'Wait Hrs Exclude More Than Two: '}</span>
                      {waitHoursExcludeMoreThanTwo}
                    </div>
                  </div> */}
                    </>}>
                      <div className="data leftPadding">
                        <p className="fontBold">Wait</p>
                        <div className="contents">
                          <div className="sub-contents me-2">
                            <p>Hours</p>
                            <p>{waitHours}</p>
                          </div>
                        </div>
                      </div>
                    </BootstrapTooltip>

                  </>
                }
              </div>
            </div>
        </div>
      </DataContainer>
      }
     {!decreaseWidthSch &&
      <>
      <StaticArrowUpDown decreaseWidthPlan={decreaseWidthPlan} onClick={handleClickPlan}>
        <div ref={titleRef}>
          {decreaseWidthPlan ? (
            <IoIosArrowUp size={18} color={PURPLE} />
          ) : (
            <IoIosArrowDown size={18} color={PURPLE} />
          )}
        </div>
      </StaticArrowUpDown>
      <CalendarContainer
        className="App"
        progress={showProgressSection}
        reduceColumns={shiftType === CALENDAR_WEEKLY}
        plannerView={!decreaseWidthPlan}
      // view={CALENDAR_DAILY}
      >
        <FullCalendar
          schedulerLicenseKey={'0149351374-fcs-1640812113'}
          scrollTime={moment().subtract(1, 'hours').format('HH:00:00')}
          ref={(el) => (calendarRef.current[0] = el)}
          plugins={[timeLinePlugin, interactionPlugin, bootstrapPlugin]}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          // selectable={true}
          firstDay={1}
          // expandRows={true}
          height={decreaseWidthPlan ? "150%" : "100%"}
          headerToolbar={{
            left: 'title',
            center: '',
            end: '',
          }}
          views={{
            resourceTimelineDay: {
              type: 'resourceTimelineDay',
              buttonText: 'Daily',
              slotDuration: '00:05:00',
              eventMinWidth: 1,
              slotMinWidth: 10
            },


            resourceTimelineWeekDays: {
              type: 'resourceTimelineWeek',
              duration: { weeks: 1 },
              slotDuration: { days: 1 },
              slotLabelFormat: [
                { month: 'long', year: 'numeric' }, // top level of text
                {
                  day: 'numeric',
                  weekday: 'short',
                  omitCommas: true,
                },
              ],
              buttonText: 'Weekly',
            },
          }}
          slotLabelFormat={{ hour: 'numeric', minute: '2-digit', omitZeroMinute: false, hour12: false }}
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            hour12: false,
            // timeZoneName: 'short'
          }}
          titleFormat={ // will produce something like "Tuesday, September 18, 2018"
            (shiftType === CALENDAR_DAILY) ? {
              month: 'short',
              year: 'numeric',
              day: 'numeric',
              weekday: 'short'
            } : {
              month: 'short',
              year: 'numeric',
              day: 'numeric',
            }
          }
          nextDayThreshold={'06:00'}
          initialView={CALENDAR_DAILY}
          filterResourcesWithEvents={true}
          resourceOrder="title employee"
          resourceAreaHeaderContent={'Team (Planned)'}
          resourceAreaWidth='8rem'
          resources={[...resources]}
          events={[...eventsPlanned]}
          themeSystem="bootstrap"
          viewClassNames={viewClassNames}
          lazyFetching={false}
          editable={editMode ? true : false}
          eventOrder={'start,orderEvent,allDay,title'}
          eventChange={event => handleVisitChange(event)}
          //eventLeave={event => handleVisitChange(event)}
          //eventDragStop={remove}
          timeZone={timeZone ? timeZone : 'none'}
          nowIndicator={true}
          eventContent={function (args, createElement) {

            if (args.event._def.extendedProps.icon) {
              const icon = args.event._def.extendedProps.icon;
              return (
                <>
                  <BootstrapTooltip title={<>
                    <div style={{ display: "flex" }}>
                      <span style={{ fontWeight: "bold", marginRight: "3px" }}>{
                        args.timeText ? args.timeText
                          : moment.utc(args.event._instance.range.start).format("HH:mm") + "-" + moment.utc(args.event._instance.range.end).format("HH:mm")
                      }</span>
                      <span>{args.event._def.title}</span>
                    </div>
                    <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Gender: '}
                            </span>
                            {args.event._def.extendedProps.gender ?? 'All'}
                        </div>
                        {args.event._def.extendedProps.assigned_by &&
                        <div>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Assigned By: '}
                            </span>
                            {args.event._def.extendedProps.assigned_by}
                          </div>
                        </div>}
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Earliest Start Time: '}</span>
                        {args.event._def.extendedProps.earliest_start_time && args.event._def.extendedProps.earliest_start_time.slice(0, -3)}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Latest Start Time: '}</span>
                        {args.event._def.extendedProps.latest_start_time && args.event._def.extendedProps.latest_start_time.slice(0, -3)}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Visit Type: '}</span>
                        {args.event._def.extendedProps.visit_type}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div><span style={{ fontWeight: "bold" }}>{'Service Category: '}</span>
                        {args.event._def.extendedProps.service_category}</div>
                    </div>
                  </>
                  }
                    arrow
                  >
                    <div className='d-flex flex-column' style={compactEventItemStyle}>
                      <div className='d-flex justify-content-between'>
                        <div className='me-1' style={{display: "flex", gap: "0.25rem", alignItems: "center"}}>
                        {args.timeText.replace(/ /g, '') && 
                          <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText.replace(/ /g, '')}
                          </span>
                        }
                          {(!args.event._def.extendedProps.earliest_start_time && !args.event._def.extendedProps.latest_start_time) && <FiClock />}
                          {args.event._def.extendedProps.is_published && (
                            <span><BsCheck /></span>
                          )}
                        <span>
                          {args.event._def.extendedProps.gender
                            ? args.event._def.extendedProps.gender.substring(
                              0,
                              1
                            )
                            : 'A'}
                        </span>
                        <span>
                          {args.event._def.extendedProps.priority_icon}
                        </span>
                        {args.event._def.extendedProps.isRegular && (
                          <span className="px-1">R</span>
                        )}
                        </div>
                        <span>{args.event._def.extendedProps.assigned_by}</span>
                      </div>
                      <div
                        style={compactEventItemTitleStyle}
                      >
                        <span style={{marginRight: "0.25rem"}}>{icon}</span>
                        <span>{args.event._def.title.substring(0, 15)}</span>                        
                      </div>
                    </div>
                  </BootstrapTooltip>
                </>
              )
            }
            else if (args.event._def.extendedProps.typeVisit && args.event._def.extendedProps.travelMode) {
              const icon = args.event._def.extendedProps.travelMode;
              return (
                <>
                  <BootstrapTooltip title={<>
                    <div style={{ display: "flex" }}>
                      <span style={{ fontWeight: "bold", marginRight: "3px" }}>{
                        args.timeText ? args.timeText
                          : moment.utc(args.event._instance.range.start).format("HH:mm") + "-" + moment.utc(args.event._instance.range.end).format("HH:mm")
                      }</span>
                      <span>{args.event._def.title}</span>
                    </div>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>
                        {'Gender: '}
                      </span>
                      {args.event._def.extendedProps.gender ?? 'All'}
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Earliest Start Time: '}</span>
                        {args.event._def.extendedProps.earliest_start_time && args.event._def.extendedProps.earliest_start_time.slice(0, -3)}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Latest Start Time: '}</span>
                        {args.event._def.extendedProps.latest_start_time && args.event._def.extendedProps.latest_start_time.slice(0, -3)}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Clocked In Time: '}</span>
                        {args.event._def.extendedProps.actual_visit_start_time && moment(args.event._def.extendedProps.actual_visit_start_time).format('HH:mm')}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Clocked Out Time: '}</span>
                        {args.event._def.extendedProps.actual_visit_end_time && moment(args.event._def.extendedProps.actual_visit_end_time).format('HH:mm')}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Visit Type: '}</span>
                        {args.event._def.extendedProps.visit_type}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div><span style={{ fontWeight: "bold" }}>{'Service Category: '}</span>
                        {args.event._def.extendedProps.service_category}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div><span style={{ fontWeight: "bold" }}>{'Address: '}</span>
                        {args.event._def.extendedProps.address}</div>
                    </div>
                    <div>
                      <div><span style={{ fontWeight: "bold" }}>{'Postal Code: '}</span>
                        {args.event._def.extendedProps.zipcode}</div>
                    </div>
                  </>
                  }
                    arrow
                  >
                    <div className="d-flex flex-column"
                    style={compactEventItemStyle}>
                    <div style={{display: "flex", gap: "0.25rem"}}>
                      {args.timeText.replace(/ /g, '') && 
                          <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText.replace(/ /g, '')}
                          </span>
                        }
                      <span>
                        {args.event._def.extendedProps.gender
                          ? args.event._def.extendedProps.gender.substring(0, 1)
                          : 'A'}
                      </span>
                      <span>{args.event._def.extendedProps.priority_icon}</span>
                      {args.event._def.title !== 'Unavailable' && <>{icon}</>}
                      </div>
                      <div style={compactEventItemTitleStyle}>{args.event._def.title}</div>
                    </div>
                  </BootstrapTooltip>
                </>
              )
            }
            else if (args.event._def.extendedProps.typeVisit) {
              return (
                <>
                  <BootstrapTooltip title={<>
                    <div>
                      <span style={{ fontWeight: "bold", marginRight: "3px" }}>{
                        args.timeText ? args.timeText
                          : moment.utc(args.event._instance.range.start).format("HH:mm") + "-" + moment.utc(args.event._instance.range.end).format("HH:mm")
                      }</span>
                      <span>{args.event._def.title}</span>
                    </div>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>
                        {'Gender: '}
                      </span>
                      {args.event._def.extendedProps.gender ?? 'All'}
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Earliest Start Time: '}</span>
                        {args.event._def.extendedProps.earliest_start_time && args.event._def.extendedProps.earliest_start_time.slice(0, -3)}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Latest Start Time: '}</span>
                        {args.event._def.extendedProps.latest_start_time && args.event._def.extendedProps.latest_start_time.slice(0, -3)}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Clocked In Time: '}</span>
                        {args.event._def.extendedProps.actual_visit_start_time && moment(args.event._def.extendedProps.actual_visit_start_time).format('HH:mm')}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Clocked Out Time: '}</span>
                        {args.event._def.extendedProps.actual_visit_end_time && moment(args.event._def.extendedProps.actual_visit_end_time).format('HH:mm')}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Visit Type: '}</span>
                        {args.event._def.extendedProps.visit_type}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div><span style={{ fontWeight: "bold" }}>{'Service Category: '}</span>
                        {args.event._def.extendedProps.service_category}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div><span style={{ fontWeight: "bold" }}>{'Address: '}</span>
                        {args.event._def.extendedProps.address}</div>
                    </div>
                    <div>
                      <div><span style={{ fontWeight: "bold" }}>{'Postal Code: '}</span>
                        {args.event._def.extendedProps.zipcode}</div>
                    </div>
                  </>
                  }
                    arrow
                  >
                    <div className="d-flex flex-column"
                    style={compactEventItemStyle}>
                      <div className='d-flex justify-content-between'>
                        <div className='me-1' style={{display: "flex", gap: "0.25rem", alignItems: "center"}}>
                        {args.timeText.replace(/ /g, '') && 
                          <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText.replace(/ /g, '')}
                          </span>
                        }
                          <span>{(!args.event._def.extendedProps.earliest_start_time && !args.event._def.extendedProps.latest_start_time) && <FiClock />}</span>
                          {args.event._def.extendedProps.is_published && (
                            <span><BsCheck /></span>
                          )}
                        <span>
                          {args.event._def.extendedProps.gender
                            ? args.event._def.extendedProps.gender.substring(
                              0,
                              1
                            )
                            : 'A'}
                        </span>
                        <span>
                          {args.event._def.extendedProps.priority_icon}
                        </span>
                        {args.event._def.extendedProps.isRegular && (
                          <span className="px-1">R</span>
                        )}
                        </div>
                        <span>{args.event._def.extendedProps.assigned_by}</span>
                      </div>
                      <div
                        style={compactEventItemTitleStyle}
                      >
                        {args.event._def.title.substring(0, 15)}                      
                      </div>
                    </div>
                  </BootstrapTooltip>
                </>
              )
            }
            else if (args.event._def.extendedProps.travelMode) {
              const icon = args.event._def.extendedProps.travelMode;
              return (
                <>
                  <BootstrapTooltip title={<>
                    <div>
                      <span style={{ fontWeight: "bold", marginRight: "3px" }}>{
                        args.timeText ? args.timeText
                          : moment.utc(args.event._instance.range.start).format("HH:mm") + "-" + moment.utc(args.event._instance.range.end).format("HH:mm")
                      }</span>
                      <span>{args.event._def.title}</span>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Earliest Start Time: '}</span>
                        {args.event._def.extendedProps.earliest_start_time && args.event._def.extendedProps.earliest_start_time.slice(0, -3)}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Latest Start Time: '}</span>
                        {args.event._def.extendedProps.latest_start_time && args.event._def.extendedProps.latest_start_time.slice(0, -3)}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Clocked In Time: '}</span>
                        {args.event._def.extendedProps.actual_visit_start_time && moment(args.event._def.extendedProps.actual_visit_start_time).format('HH:mm')}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Clocked Out Time: '}</span>
                        {args.event._def.extendedProps.actual_visit_end_time && moment(args.event._def.extendedProps.actual_visit_end_time).format('HH:mm')}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Visit Type: '}</span>
                        {args.event._def.extendedProps.visit_type}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div><span style={{ fontWeight: "bold" }}>{'Service Category: '}</span>
                        {args.event._def.extendedProps.service_category}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div><span style={{ fontWeight: "bold" }}>{'Address: '}</span>
                        {args.event._def.extendedProps.address}</div>
                    </div>
                    <div>
                      <div><span style={{ fontWeight: "bold" }}>{'Postal Code: '}</span>
                        {args.event._def.extendedProps.zipcode}</div>
                    </div>
                  </>
                  }
                    arrow
                  >
                    <div 
                      style={compactEventItemStyle}>
                        <div>
                        {args.timeText.replace(/ /g, '') && 
                          <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText.replace(/ /g, '')}
                          </span>
                        }
                        <span>
                          {args.event._def.extendedProps.gender
                            ? args.event._def.extendedProps.gender.substring(
                              0,
                              1
                            )
                            : 'A'}
                        </span>
                      <span>{args.event._def.extendedProps.priority_icon}</span>
                      {args.event._def.title !== 'Unavailable' && <>{icon}</>}
                        </div>
                      <div
                        style={compactEventItemTitleStyle}>{args.event._def.title.substring(0, 15)}</div>
                    </div>
                  </BootstrapTooltip>
                </>
              )
            }
            else if (args.event._def.extendedProps.clientView) {
              return (
                <>
                  <BootstrapTooltip title={<>
                    <div>
                      <span style={{ fontWeight: "bold", marginRight: "3px" }}>{
                        args.timeText ? args.timeText
                          : moment.utc(args.event._instance.range.start).format("HH:mm") + "-" + moment.utc(args.event._instance.range.end).format("HH:mm")
                      }</span>
                      <span>{args.event._def.title}</span>
                    </div>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>
                        {'Gender: '}
                      </span>
                      {args.event._def.extendedProps.gender ?? 'All'}
                    </div>
                    {args.event._def.extendedProps.assigned_by &&
                      <div>
                        <div>
                          <span style={{ fontWeight: 'bold' }}>
                            {'Assigned By: '}
                          </span>
                          {args.event._def.extendedProps.assigned_by}
                        </div>
                    </div>}
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Earliest Start Time: '}</span>
                        {args.event._def.extendedProps.earliest_start_time && args.event._def.extendedProps.earliest_start_time.slice(0, -3)}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Latest Start Time: '}</span>
                        {args.event._def.extendedProps.latest_start_time && args.event._def.extendedProps.latest_start_time.slice(0, -3)}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Clocked In Time: '}</span>
                        {args.event._def.extendedProps.actual_visit_start_time && moment(args.event._def.extendedProps.actual_visit_start_time).format('HH:mm')}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Clocked Out Time: '}</span>
                        {args.event._def.extendedProps.actual_visit_end_time && moment(args.event._def.extendedProps.actual_visit_end_time).format('HH:mm')}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Visit Type: '}</span>
                        {args.event._def.extendedProps.visit_type}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div><span style={{ fontWeight: "bold" }}>{'Service Category: '}</span>
                        {args.event._def.extendedProps.service_category}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div><span style={{ fontWeight: "bold" }}>{'Address: '}</span>
                        {args.event._def.extendedProps.address}</div>
                    </div>
                    <div>
                      <div><span style={{ fontWeight: "bold" }}>{'Postal Code: '}</span>
                        {args.event._def.extendedProps.zipcode}</div>
                    </div>
                  </>
                  }
                    arrow
                  >
                    <div className="d-flex flex-column"
                    style={compactEventItemStyle}>
                      <div className='d-flex justify-content-between'>
                        <div className='me-1' style={{display: "flex", gap: "0.25rem"}}>
                          {args.timeText.replace(/ /g, '') && 
                            <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                              {args.timeText.replace(/ /g, '')}
                            </span>
                          }
                        <span>{(!args.event._def.extendedProps.earliest_start_time && !args.event._def.extendedProps.latest_start_time) && <FiClock />}</span>
                        <span>{args.event._def.extendedProps.is_published ? <BsCheck /> : ''}</span>
                        <span>{args.event._def.extendedProps.gender ?
                          (args.event._def.extendedProps.gender).substring(0, 1) : "A"
                          }</span>
                        <span>{args.event._def.extendedProps.priority_icon}</span>
                        {args.event._def.extendedProps.isRegular && <span className='px-1'>
                          R
                        </span>}
                        <span>{args.event._def.extendedProps.assigned_by}</span>
                        </div>
                        <div
                        style={compactEventItemTitleStyle}
                      >
                        {args.event._def.title.substring(0, 15)}                    
                      </div>
                      </div>
                      <div>

                      </div>
                    </div>

                  </BootstrapTooltip>
                </>
              )
            }
            else if (args.event._def.extendedProps.waittime) {
              return (
                <>
                  <BootstrapTooltip>
                    <div style={compactEventItemStyle}>
                      {args.timeText.replace(/ /g, '') && 
                          <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText.replace(/ /g, '')}
                          </span>
                        }
                      <span style={compactEventItemTitleStyle}>{args.event._def.title}</span>
                    </div>
                  </BootstrapTooltip>
                </>
              )
            }

          }
          }
          resourceLabelContent={function (args) {
            if (calendarView === 'team' || rotaShift) {
              let employee_det = employeeVisitDataPlanned && employeeVisitDataPlanned.find(det => det.employee_id === args.resource._resource.id)
              return (
                <>
                  <BootstrapTooltip title={<>
                    <div style={{ fontSize: "13px" }}>
                      <span style={{ marginRight: "4px" }}>Total Visits:</span>
                      <span>{employee_det && employee_det.no_visits}</span>
                    </div>
                    <div style={{ fontSize: "13px" }}>
                      <span style={{ marginRight: "4px" }}>Visits Hrs:</span>
                      <span>{employee_det && (
                        (employee_det.total_visit_hours - Math.floor(employee_det.total_visit_hours)) === 0
                          ? employee_det.total_visit_hours : employee_det.total_visit_hours
                      )
                      }</span>
                    </div>
                    <div style={{ fontSize: "13px" }}>
                      <span style={{ marginRight: "4px" }}>Contracted Hrs:</span>
                      <span>{
                        employee_det && (
                          (employee_det.contracted_no_hours - Math.floor(employee_det.contracted_no_hours)) === 0
                            ? employee_det.contracted_no_hours : employee_det.contracted_no_hours
                        )
                      }</span>
                    </div>
                    <div style={{ fontSize: "13px" }}>
                      <span style={{ marginRight: "4px" }}>Desired Hrs:</span>
                      <span>{
                        employee_det && (
                          (employee_det.max_hour_per_week - Math.floor(employee_det.max_hour_per_week)) === 0
                            ? employee_det.max_hour_per_week : employee_det.max_hour_per_week
                        )
                      }</span>
                    </div>
                    <div style={{ fontSize: "13px" }}>
                      <span style={{ marginRight: "4px" }}>Travel Hrs:</span>
                      <span>{
                        employee_det && (
                          (employee_det.schedule_travel_time - Math.floor(employee_det.schedule_travel_time)) === 0
                            ? employee_det.schedule_travel_time : employee_det.schedule_travel_time.toFixed(2)
                        )
                      }</span>
                    </div>
                    <div style={{ fontSize: "13px" }}>
                      <span style={{ marginRight: "4px" }}>Travel Distance:</span>
                      <span>{
                        employee_det && employee_det.actual_travel_miles && (
                          (employee_det.actual_travel_miles - Math.floor(employee_det.actual_travel_miles)) === 0
                            ? employee_det.actual_travel_miles : employee_det.actual_travel_miles
                        )
                      }</span>
                    </div>
                    <div style={{ fontSize: "13px" }}>
                      <span style={{ marginRight: "4px" }}>Wait Hrs:</span>
                      <span>{
                        employee_det && (
                          (employee_det.duration - Math.floor(employee_det.duration)) === 0
                            ? employee_det.duration : employee_det.duration
                        )
                      }</span>
                    </div>
                    <div style={{ fontSize: "13px" }}>
                      <span style={{ marginRight: "4px" }}>Remain Desired Wk Hrs:</span>
                      {/* <span>{
                        employee_det && (
                          (employee_det.remaining_desired_hrs - Math.floor(employee_det.remaining_desired_hrs)) === 0
                            ? employee_det.remaining_desired_hrs : employee_det.remaining_desired_hrs
                        )
                      }</span> */}
                      <span>{
                        employee_det && employee_det.max_hour_per_week && employee_det.total_visit_hours && (
                          ((employee_det.max_hour_per_week - employee_det.total_visit_hours) - Math.floor(employee_det.max_hour_per_week - employee_det.total_visit_hours)) === 0 ?
                            (employee_det.max_hour_per_week - employee_det.total_visit_hours) : (employee_det.max_hour_per_week - employee_det.total_visit_hours).toFixed(2)
                        )}</span>
                    </div>
                    <div style={{ fontSize: "13px" }}>
                      <span style={{ marginRight: "4px" }}>Remain Contrd Wk Hrs:</span>
                      {/* <span>{
                        employee_det && (
                          (employee_det.remaining_contracted_desired_hrs - Math.floor(employee_det.remaining_contracted_desired_hrs)) === 0
                            ? employee_det.remaining_contracted_desired_hrs : employee_det.remaining_contracted_desired_hrs
                        )
                      }</span> */}
                      <span>{
                        employee_det && employee_det.contracted_no_hours && employee_det.total_visit_hours && (
                          ((employee_det.contracted_no_hours - employee_det.total_visit_hours) - Math.floor(employee_det.contracted_no_hours - employee_det.total_visit_hours)) === 0 ?
                            (employee_det.contracted_no_hours - employee_det.total_visit_hours) : (employee_det.contracted_no_hours - employee_det.total_visit_hours).toFixed(2)
                        )}</span>
                    </div>
                  </>
                  }
                    arrow
                  >
                    <div>
                      {args.fieldValue}
                    </div>

                  </BootstrapTooltip>
                </>
              )
            }
          }
          }
        />
      </CalendarContainer>
      </>}

     {!decreaseWidthPlan &&
      <>
      <StaticArrowUpDown decreaseWidthSch={decreaseWidthSch} onClick={handleClickSch}>
        <div ref={titleRef}>
          {decreaseWidthSch ? (
            <IoIosArrowUp size={18} color={PURPLE} />
          ) : (
            <IoIosArrowDown size={18} color={PURPLE} />
          )}
        </div>
      </StaticArrowUpDown>
      <CalendarContainer
        className="App2"
        progress={showProgressSection}
        reduceColumns={shiftType === CALENDAR_WEEKLY}
        plannerView={!decreaseWidthSch}
      // view={CALENDAR_DAILY}
      >
        <FullCalendar
          schedulerLicenseKey={'0149351374-fcs-1640812113'}
          scrollTime={moment().subtract(1, 'hours').format('HH:00:00')}
          ref={(el) => (calendarRef.current[1] = el)}
          plugins={[timeLinePlugin, interactionPlugin, bootstrapPlugin]}
          droppable={true}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          // selectable={true}
          firstDay={1}
          // expandRows={true}
          height={decreaseWidthSch ? "150%" : "100%"}
          headerToolbar={{
            left: '',
            center: '',
            end: '',
          }}
          views={{
            resourceTimelineDay: {
              type: 'resourceTimelineDay',
              buttonText: 'Daily',
              slotDuration: '00:05:00',
              eventMinWidth: 1,
              slotMinWidth: 10
            },


            resourceTimelineWeekDays: {
              type: 'resourceTimelineWeek',
              duration: { weeks: 1 },
              slotDuration: { days: 1 },
              slotLabelFormat: [
                { month: 'long', year: 'numeric' }, // top level of text
                {
                  day: 'numeric',
                  weekday: 'short',
                  omitCommas: true,
                },
              ],
              buttonText: 'Weekly',
            },
          }}
          slotLabelFormat={{ hour: 'numeric', minute: '2-digit', omitZeroMinute: false, hour12: false }}
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            hour12: false,
            // timeZoneName: 'short'
          }}
          nextDayThreshold={'06:00'}
          initialView={CALENDAR_DAILY}
          filterResourcesWithEvents={true}
          resourceOrder="title employee"
          resourceAreaHeaderContent={'Team (Scheduled)'}
          resourceAreaWidth='8rem'
          resources={[...resources]}
          events={[...eventsValue]}
          themeSystem="bootstrap"
          viewClassNames={viewClassNames}
          lazyFetching={false}
          editable={editMode ? true : false}
          eventOrder={'start,orderEvent,allDay,title'}
          eventChange={event => handleVisitChange(event)}
          eventReceive={event => handleVisitChange(event)}
          //eventDragStop={remove}
          timeZone={timeZone ? timeZone : 'none'}
          nowIndicator={true}
          eventContent={function (args, createElement) {

            if (args.event._def.extendedProps.icon) {
              const icon = args.event._def.extendedProps.icon;
              return (
                <>
                  <BootstrapTooltip title={<>
                    <div style={{ display: "flex" }}>
                      <span style={{ fontWeight: "bold", marginRight: "3px" }}>{
                        args.timeText ? args.timeText
                          : moment.utc(args.event._instance.range.start).format("HH:mm") + "-" + moment.utc(args.event._instance.range.end).format("HH:mm")
                      }</span>
                      <span>{args.event._def.title}</span>
                    </div>
                    <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Gender: '}
                            </span>
                            {args.event._def.extendedProps.gender ?? 'All'}
                        </div>
                        {args.event._def.extendedProps.assigned_by &&
                        <div>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Assigned By: '}
                            </span>
                            {args.event._def.extendedProps.assigned_by}
                          </div>
                        </div>}
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Earliest Start Time: '}</span>
                        {args.event._def.extendedProps.earliest_start_time && args.event._def.extendedProps.earliest_start_time.slice(0, -3)}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Latest Start Time: '}</span>
                        {args.event._def.extendedProps.latest_start_time && args.event._def.extendedProps.latest_start_time.slice(0, -3)}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Visit Type: '}</span>
                        {args.event._def.extendedProps.visit_type}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div><span style={{ fontWeight: "bold" }}>{'Service Category: '}</span>
                        {args.event._def.extendedProps.service_category}</div>
                    </div>
                  </>
                  }
                    arrow
                  >
                    <div className='d-flex flex-column' style={compactEventItemStyle}>
                      <div className='d-flex justify-content-between'>
                        <div className='me-1' style={{display: "flex", gap: "0.25rem", alignItems: "center"}}>
                        {args.timeText.replace(/ /g, '') && 
                          <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText.replace(/ /g, '')}
                          </span>
                        }
                          {(!args.event._def.extendedProps.earliest_start_time && !args.event._def.extendedProps.latest_start_time) && <FiClock />}
                          {args.event._def.extendedProps.is_published && (
                            <span><BsCheck /></span>
                          )}
                        <span>
                          {args.event._def.extendedProps.gender
                            ? args.event._def.extendedProps.gender.substring(
                              0,
                              1
                            )
                            : 'A'}
                        </span>
                        <span>
                          {args.event._def.extendedProps.priority_icon}
                        </span>
                        {args.event._def.extendedProps.isRegular && (
                          <span className="px-1">R</span>
                        )}
                        </div>
                        <span>{args.event._def.extendedProps.assigned_by}</span>
                      </div>
                      <div
                        style={compactEventItemTitleStyle}
                      >
                        <span style={{marginRight: "0.25rem"}}>{icon}</span>
                        <span>{args.event._def.title.substring(0, 15)}</span>                        
                      </div>
                    </div>
                  </BootstrapTooltip>
                </>
              )
            }
            else if (args.event._def.extendedProps.typeVisit && args.event._def.extendedProps.travelMode) {
              const icon = args.event._def.extendedProps.travelMode;
              return (
                <>
                  <BootstrapTooltip title={<>
                    <div style={{ display: "flex" }}>
                      <span style={{ fontWeight: "bold", marginRight: "3px" }}>{
                        args.timeText ? args.timeText
                          : moment.utc(args.event._instance.range.start).format("HH:mm") + "-" + moment.utc(args.event._instance.range.end).format("HH:mm")
                      }</span>
                      <span>{args.event._def.title}</span>
                    </div>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>
                        {'Gender: '}
                      </span>
                      {args.event._def.extendedProps.gender ?? 'All'}
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Earliest Start Time: '}</span>
                        {args.event._def.extendedProps.earliest_start_time && args.event._def.extendedProps.earliest_start_time.slice(0, -3)}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Latest Start Time: '}</span>
                        {args.event._def.extendedProps.latest_start_time && args.event._def.extendedProps.latest_start_time.slice(0, -3)}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Clocked In Time: '}</span>
                        {args.event._def.extendedProps.actual_visit_start_time && moment(args.event._def.extendedProps.actual_visit_start_time).format('HH:mm')}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Clocked Out Time: '}</span>
                        {args.event._def.extendedProps.actual_visit_end_time && moment(args.event._def.extendedProps.actual_visit_end_time).format('HH:mm')}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Visit Type: '}</span>
                        {args.event._def.extendedProps.visit_type}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div><span style={{ fontWeight: "bold" }}>{'Service Category: '}</span>
                        {args.event._def.extendedProps.service_category}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div><span style={{ fontWeight: "bold" }}>{'Address: '}</span>
                        {args.event._def.extendedProps.address}</div>
                    </div>
                    <div>
                      <div><span style={{ fontWeight: "bold" }}>{'Postal Code: '}</span>
                        {args.event._def.extendedProps.zipcode}</div>
                    </div>
                  </>
                  }
                    arrow
                  >
                    <div className="d-flex flex-column"
                    style={compactEventItemStyle}>
                    <div style={{display: "flex", gap: "0.25rem"}}>
                      {args.timeText.replace(/ /g, '') && 
                          <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText.replace(/ /g, '')}
                          </span>
                        }
                      <span>
                        {args.event._def.extendedProps.gender
                          ? args.event._def.extendedProps.gender.substring(0, 1)
                          : 'A'}
                      </span>
                      <span>{args.event._def.extendedProps.priority_icon}</span>
                      {args.event._def.title !== 'Unavailable' && <>{icon}</>}
                      </div>
                      <div style={compactEventItemTitleStyle}>{args.event._def.title}</div>
                    </div>
                  </BootstrapTooltip>
                </>
              )
            }
            else if (args.event._def.extendedProps.typeVisit) {
              return (
                <>
                  <BootstrapTooltip title={<>
                    <div>
                      <span style={{ fontWeight: "bold", marginRight: "3px" }}>{
                        args.timeText ? args.timeText
                          : moment.utc(args.event._instance.range.start).format("HH:mm") + "-" + moment.utc(args.event._instance.range.end).format("HH:mm")
                      }</span>
                      <span>{args.event._def.title}</span>
                    </div>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>
                        {'Gender: '}
                      </span>
                      {args.event._def.extendedProps.gender ?? 'All'}
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Earliest Start Time: '}</span>
                        {args.event._def.extendedProps.earliest_start_time && args.event._def.extendedProps.earliest_start_time.slice(0, -3)}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Latest Start Time: '}</span>
                        {args.event._def.extendedProps.latest_start_time && args.event._def.extendedProps.latest_start_time.slice(0, -3)}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Clocked In Time: '}</span>
                        {args.event._def.extendedProps.actual_visit_start_time && moment(args.event._def.extendedProps.actual_visit_start_time).format('HH:mm')}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Clocked Out Time: '}</span>
                        {args.event._def.extendedProps.actual_visit_end_time && moment(args.event._def.extendedProps.actual_visit_end_time).format('HH:mm')}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Visit Type: '}</span>
                        {args.event._def.extendedProps.visit_type}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div><span style={{ fontWeight: "bold" }}>{'Service Category: '}</span>
                        {args.event._def.extendedProps.service_category}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div><span style={{ fontWeight: "bold" }}>{'Address: '}</span>
                        {args.event._def.extendedProps.address}</div>
                    </div>
                    <div>
                      <div><span style={{ fontWeight: "bold" }}>{'Postal Code: '}</span>
                        {args.event._def.extendedProps.zipcode}</div>
                    </div>
                  </>
                  }
                    arrow
                  >
                    <div className="d-flex flex-column"
                    style={compactEventItemStyle}>
                      <div className='d-flex justify-content-between'>
                        <div className='me-1' style={{display: "flex", gap: "0.25rem", alignItems: "center"}}>
                        {args.timeText.replace(/ /g, '') && 
                          <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText.replace(/ /g, '')}
                          </span>
                        }
                          <span>{(!args.event._def.extendedProps.earliest_start_time && !args.event._def.extendedProps.latest_start_time) && <FiClock />}</span>
                          {args.event._def.extendedProps.is_published && (
                            <span><BsCheck /></span>
                          )}
                        <span>
                          {args.event._def.extendedProps.gender
                            ? args.event._def.extendedProps.gender.substring(
                              0,
                              1
                            )
                            : 'A'}
                        </span>
                        <span>
                          {args.event._def.extendedProps.priority_icon}
                        </span>
                        {args.event._def.extendedProps.isRegular && (
                          <span className="px-1">R</span>
                        )}
                        </div>
                        <span>{args.event._def.extendedProps.assigned_by}</span>
                      </div>
                      <div
                        style={compactEventItemTitleStyle}
                      >
                        {args.event._def.title.substring(0, 15)}                      
                      </div>
                    </div>
                  </BootstrapTooltip>
                </>
              )
            }
            else if (args.event._def.extendedProps.travelMode) {
              const icon = args.event._def.extendedProps.travelMode;
              return (
                <>
                  <BootstrapTooltip title={<>
                    <div>
                      <span style={{ fontWeight: "bold", marginRight: "3px" }}>{
                        args.timeText ? args.timeText
                          : moment.utc(args.event._instance.range.start).format("HH:mm") + "-" + moment.utc(args.event._instance.range.end).format("HH:mm")
                      }</span>
                      <span>{args.event._def.title}</span>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Earliest Start Time: '}</span>
                        {args.event._def.extendedProps.earliest_start_time && args.event._def.extendedProps.earliest_start_time.slice(0, -3)}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Latest Start Time: '}</span>
                        {args.event._def.extendedProps.latest_start_time && args.event._def.extendedProps.latest_start_time.slice(0, -3)}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Clocked In Time: '}</span>
                        {args.event._def.extendedProps.actual_visit_start_time && moment(args.event._def.extendedProps.actual_visit_start_time).format('HH:mm')}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Clocked Out Time: '}</span>
                        {args.event._def.extendedProps.actual_visit_end_time && moment(args.event._def.extendedProps.actual_visit_end_time).format('HH:mm')}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Visit Type: '}</span>
                        {args.event._def.extendedProps.visit_type}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div><span style={{ fontWeight: "bold" }}>{'Service Category: '}</span>
                        {args.event._def.extendedProps.service_category}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div><span style={{ fontWeight: "bold" }}>{'Address: '}</span>
                        {args.event._def.extendedProps.address}</div>
                    </div>
                    <div>
                      <div><span style={{ fontWeight: "bold" }}>{'Postal Code: '}</span>
                        {args.event._def.extendedProps.zipcode}</div>
                    </div>
                  </>
                  }
                    arrow
                  >
                    <div 
                      style={compactEventItemStyle}>
                        <div>
                        {args.timeText.replace(/ /g, '') && 
                          <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText.replace(/ /g, '')}
                          </span>
                        }
                        <span>
                          {args.event._def.extendedProps.gender
                            ? args.event._def.extendedProps.gender.substring(
                              0,
                              1
                            )
                            : 'A'}
                        </span>
                      <span>{args.event._def.extendedProps.priority_icon}</span>
                      {args.event._def.title !== 'Unavailable' && <>{icon}</>}
                        </div>
                      <div
                        style={compactEventItemTitleStyle}>{args.event._def.title.substring(0, 15)}</div>
                    </div>
                  </BootstrapTooltip>
                </>
              )
            }
            else if (args.event._def.extendedProps.clientView) {
              return (
                <>
                  <BootstrapTooltip title={<>
                    <div>
                      <span style={{ fontWeight: "bold", marginRight: "3px" }}>{
                        args.timeText ? args.timeText
                          : moment.utc(args.event._instance.range.start).format("HH:mm") + "-" + moment.utc(args.event._instance.range.end).format("HH:mm")
                      }</span>
                      <span>{args.event._def.title}</span>
                    </div>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>
                        {'Gender: '}
                      </span>
                      {args.event._def.extendedProps.gender ?? 'All'}
                    </div>
                    {args.event._def.extendedProps.assigned_by &&
                      <div>
                        <div>
                          <span style={{ fontWeight: 'bold' }}>
                            {'Assigned By: '}
                          </span>
                          {args.event._def.extendedProps.assigned_by}
                        </div>
                    </div>}
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Earliest Start Time: '}</span>
                        {args.event._def.extendedProps.earliest_start_time && args.event._def.extendedProps.earliest_start_time.slice(0, -3)}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Latest Start Time: '}</span>
                        {args.event._def.extendedProps.latest_start_time && args.event._def.extendedProps.latest_start_time.slice(0, -3)}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Clocked In Time: '}</span>
                        {args.event._def.extendedProps.actual_visit_start_time && moment(args.event._def.extendedProps.actual_visit_start_time).format('HH:mm')}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Clocked Out Time: '}</span>
                        {args.event._def.extendedProps.actual_visit_end_time && moment(args.event._def.extendedProps.actual_visit_end_time).format('HH:mm')}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Visit Type: '}</span>
                        {args.event._def.extendedProps.visit_type}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div><span style={{ fontWeight: "bold" }}>{'Service Category: '}</span>
                        {args.event._def.extendedProps.service_category}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div><span style={{ fontWeight: "bold" }}>{'Address: '}</span>
                        {args.event._def.extendedProps.address}</div>
                    </div>
                    <div>
                      <div><span style={{ fontWeight: "bold" }}>{'Postal Code: '}</span>
                        {args.event._def.extendedProps.zipcode}</div>
                    </div>
                  </>
                  }
                    arrow
                  >
                    <div className="d-flex flex-column"
                    style={compactEventItemStyle}>
                      <div className='d-flex justify-content-between'>
                        <div className='me-1' style={{display: "flex", gap: "0.25rem"}}>
                          {args.timeText.replace(/ /g, '') && 
                            <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                              {args.timeText.replace(/ /g, '')}
                            </span>
                          }
                        <span>{(!args.event._def.extendedProps.earliest_start_time && !args.event._def.extendedProps.latest_start_time) && <FiClock />}</span>
                        <span>{args.event._def.extendedProps.is_published ? <BsCheck /> : ''}</span>
                        <span>{args.event._def.extendedProps.gender ?
                          (args.event._def.extendedProps.gender).substring(0, 1) : "A"
                          }</span>
                        <span>{args.event._def.extendedProps.priority_icon}</span>
                        {args.event._def.extendedProps.isRegular && <span className='px-1'>
                          R
                        </span>}
                        <span>{args.event._def.extendedProps.assigned_by}</span>
                        </div>
                        <div
                        style={compactEventItemTitleStyle}
                      >
                        {args.event._def.title.substring(0, 15)}                    
                      </div>
                      </div>
                      <div>

                      </div>
                    </div>

                  </BootstrapTooltip>
                </>
              )
            }
            else if (args.event._def.extendedProps.waittime) {
              return (
                <>
                  <BootstrapTooltip>
                    <div style={compactEventItemStyle}>
                      {args.timeText.replace(/ /g, '') && 
                          <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText.replace(/ /g, '')}
                          </span>
                        }
                      <span style={compactEventItemTitleStyle}>{args.event._def.title}</span>
                    </div>
                  </BootstrapTooltip>
                </>
              )
            }

          }
          }
          resourceLabelContent={function (args) {
            if (calendarView === 'team' || rotaShift) {
              let employee_det =
                employeeVisitData &&
                employeeVisitData.find(
                  det => det.employee_id === args.resource._resource.id
                );
              return (
                <>
                  <BootstrapTooltip
                    title={
                      <>
                        <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>
                            Total Visits:
                          </span>
                          <span>{employee_det && employee_det.total_visits}</span>
                        </div>
                        <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>Visits Hrs:</span>
                          <span>
                            {employee_det && employee_det.visits_hrs }
                          </span>
                        </div>
                        <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>
                            Contracted Hrs:
                          </span>
                          <span>
                            {employee_det && employee_det.contracted_hrs}
                          </span>
                        </div>
                        <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>Desired Hrs:</span>
                          <span>
                            {employee_det && employee_det.desired_hrs}
                          </span>
                        </div>
                        <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>Travel Hrs:</span>
                          <span>
                            {employee_det && employee_det.travel_hrs}
                          </span>
                        </div>
                        <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>
                            Travel Distance:
                          </span>
                          <span>
                            {employee_det && employee_det.travel_distance}
                          </span>
                        </div>
                        {/* <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>Wait Hrs:</span>
                          <span>
                            {employee_det && employee_det.wait_hrs}
                          </span>
                        </div> */}
                        <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>
                            Remain Desired Wk Hrs:
                          </span>
                          {/* <span>{
                        employee_det && (
                          (employee_det.remaining_desired_hrs - Math.floor(employee_det.remaining_desired_hrs)) === 0
                            ? employee_det.remaining_desired_hrs : employee_det.remaining_desired_hrs
                        )
                      }</span> */}
                          <span>
                            {employee_det && employee_det.remain_desired_wk_hrs}
                          </span>
                        </div>
                        <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>
                            Remain Contrd Wk Hrs:
                          </span>
                          {/* <span>{
                        employee_det && (
                          (employee_det.remaining_contracted_desired_hrs - Math.floor(employee_det.remaining_contracted_desired_hrs)) === 0
                            ? employee_det.remaining_contracted_desired_hrs : employee_det.remaining_contracted_desired_hrs
                        )
                      }</span> */}
                          <span>
                            {employee_det &&  employee_det.remain_contrd_wk_hrs}
                          </span>
                        </div>
                        <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>
                            Default Travel Mode:
                          </span>
                          <span>
                            {employee_det && employee_det.default_travel_mode}
                          </span>
                        </div>
                        <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>
                            Phone Number:
                          </span>
                          <span>
                            {employee_det && employee_det.phone_number}
                          </span>
                        </div>
                      </>
                    }
                    arrow>
                    <div>{args.fieldValue}</div>
                  </BootstrapTooltip>
                </>
              );
            }
            if (calendarView === 'client') {
              let client_det = clinetVisitData && clinetVisitData.find(det => det.id === args.resource._resource.id)
              return (
                <>
                  <BootstrapTooltip title={<>
                    <div style={{ fontSize: "13px" }}>
                      <span style={{ marginRight: "4px" }}>Total Visits:</span>
                      <span>{client_det && client_det.visit_count}</span>
                    </div>
                    <div style={{ fontSize: "13px" }}>
                      <span style={{ marginRight: "4px" }}>Visits Hrs:</span>
                      <span>{client_det && (
                        (client_det.visit_hours - Math.floor(client_det.visit_hours)) === 0
                          ? client_det.visit_hours : client_det.visit_hours.toFixed(2)
                      )
                      }</span>
                    </div>
                  </>
                  } arrow
                  >
                    <div style={compactEventItemStyle}>
                      {args.fieldValue}
                    </div>
                  </BootstrapTooltip>
                </>
              )
            }

          }
          }
        />
      </CalendarContainer>
      </>}
     
    </Fragment>
  );
};

export default PlannedCalendar;