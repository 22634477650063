import React, { useEffect, useState, } from 'react';
import { ModalContainer } from 'shared/styles/constants/tagsStyles';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { useForm } from 'hooks/FormHook';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { CHECK_CIRCLE, ERROR_OUTLINE, FLOPPY } from 'utils/constants/icons';
import { SAVE, SERVER_ERROR } from 'utils/constants/messages';
import { Toast } from 'primereact/toast';

import _ from 'lodash';
import {
  getUser,
  patchUser,
  resetLoginAattempts,
  resetPasswordExpiryDays,
  resetUserPassword,
  resetUserPin,
} from '../../../../../utils/api/UsersApi';
import { MessageOptaPlanner } from '../../../Scheduler/styles';
import ModalInformation from '../../../../SharedComponents/ModalInformation';
import { useRef } from 'react';
import { RiCheckboxCircleFill } from "react-icons/ri";
import { IconContext } from 'react-icons';
import { setForceLogout } from 'utils/api/ClientApi';


const EditModal = ({
  fullBind,
  Id,
  roles,
  setModalValue,
  setUsers,
  showSuccessModal,
}) => {
  const { values, setValues, useInput } = useForm({});
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);
  const [errorMessage, setErrorMessage] = useState({});
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [staffValuechecked, seStaffValueChecked] = useState();
  const [defaultChecks, setDefaultChecks] = useState();
  const [copySuccessWeb, setCopySuccessWeb] = useState(false);
  const [copySuccessMobile, setCopySuccessMobile] = useState(false);
  const textAreaRef = useRef(null);
  const toast = useRef();
  const [importantList, setImportantList] = useState([])
  const dropDownHandleSelect = item => {
    //recursive objects merge
    let result;
    result = _.merge(values, item);
    setDefaultChecks(roles.find(role => role.id === item.role_id).is_staff)
    setValues({ ...result });
  };
  const loadUser = async () => {
    const response = await getUser(Id);
    setDefaultChecks(response.user.is_staff)
    // seStaffValueChecked(!defaultChecks)
    setValues({ ...values, ...response.user });
    setLoadingModal(false);
  };

  useEffect(() => {
    // get info and display it
    if (Id) {
      loadUser();
    } else {
      setLoadingModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Id]);

  useEffect(() => {
    let newTemp = values?.important_contact_client?.split(',')
    setImportantList(() => newTemp?.map((item) => {
      return {
        id: item,
        name: item,
        label: item,
        value: item
      }
    }))
  }, [values]);

  const handelSaveClick = () => {
    setLoadingSave(true);
    // setValues({...values, is_staff : defaultChecks});
    patchUser({ ...values }).then(response => {
      setLoadingSave(false);
      setUsers(response);
      setModalValue(false);

    });
  };

  const handelResetPassword = async () => {
    try {
      if (values.email) {
        setLoadingModal(true);
        const response = await resetUserPassword(values.email);
        if (response.statusCode === 404)
          throw new Error('Email for the reset' + ' password is not found.');
        setLoadingModal(false);
        setModalValue(false);
        showSuccessModal();
      }
    } catch (err) {
      setLoadingModal(false);
      setErrorMessage({ ...errorMessage, message: err.message });
      setShowErrorMessage(true);
    }
  };
  const handelResetPin = async () => {
    try {
      if (values.email) {
        setLoadingModal(true);
        const response = await resetUserPin(values.email);
        if (response.statusCode === 404)
          throw new Error('Email for the reset' + ' password is not found.');
        setLoadingModal(false);
        setModalValue(false);
        showSuccessModal();
      }
    } catch (err) {
      setLoadingModal(false);
      setErrorMessage({ ...errorMessage, message: err.message });
      setShowErrorMessage(true);
    }
  };

  const handleErrorModelCancel = () => {
    setShowErrorMessage(false);
  };
  // const checkboxStaffValues=(e)=>{
  //   console.log(e,888)
  //   // setDefaultChecks(! e.target.checked)
  // }

  function copyToClipboardWeb(e) {
    navigator.clipboard.writeText(e.target.value)
    setCopySuccessWeb(true);
    setCopySuccessMobile(false);
  };
  function copyToClipboardMobile(e) {
    navigator.clipboard.writeText(e.target.value)
    setCopySuccessMobile(true);
    setCopySuccessWeb(false);

  };
  const handleForceLogOut = () => {
    setForceLogout(Id).then((response) => {
      if (response) {

        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: response.message
          ,
        })

      }
    })
  }


  const handleResetLoginAattempts = async () => {
    try {
      const response = await resetLoginAattempts(Id)
      if (response.statusCode === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: response.message,
        })
        loadUser();
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: `${response?.message}`,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: SERVER_ERROR,
      });
    }
  }
  const handleResetPasswordExpiryDays = async () => {
    try {
      const response = await resetPasswordExpiryDays(Id)
      if (response.statusCode === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: response.message,
        })
        loadUser();
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: `${response?.message}`,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: SERVER_ERROR,
      });
    }
  }
  console.log(values.loginAttemptsLeft,"loginAttemptsLeft")
  return (
    <ModalContainer max_width={'40%'}>
      <Toast ref={toast} position="top-right" />

      <ModalDynamic
        loading={loadingModal}
        loadingMaxWidth={'10rem'}
        {...fullBind}
        title={values?.name || values?.first_name || 'Edit User'}
        max_width={'90%'}
        minWidthTitle="8.5rem">
        <div className={'p-2 p-sm-3 p-md-4 pb-4'}>
          <div className=" d-block d-md-flex">
            <div className={'w-100 pe-md-3'}>
              <InputLabelTop
                type="text"
                id="name_edit_users"
                label={'First Name'}
                {...useInput('first_name', 'isAlpha')}
              />
              <InputLabelTop
                type="text"
                id="name_edit_users"
                label={'Last Name'}
                {...useInput('last_name', 'isAlpha')}
              />
              <InputLabelTop
                type="text"
                id="email_edit_users"
                label={'Email'}
                {...useInput('email', 'isEmail')}
              />
              <InputLabelTop
                type="text"
                id="user_device_edit_users"
                label={'User Device'}
                {...useInput('user_device', '')}
              />

              <div className='d-flex'>
                <div className='w-100 pe-1'>
                  <InputLabelTop
                    readOnly
                    type="text"
                    id="loginAttemptsLeft"
                    value={values.loginAttemptsLeft}
                    label={'Login Attempts Left'}
                    // {...useInput('loginAttemptsLeft', '')}
                  />
                </div>
                <div className='w-100 ps-1'>
                  <InputLabelTop
                    readOnly
                    type="text"
                    id="passwordExpiryDaysLeft"
                    value={values.passwordExpiryDaysLeft}
                    label={'Pwd. Expiry Days Left'}
                    // {...useInput('passwordExpiryDaysLeft', '')}
                  />
                </div>
              </div>
            </div>
            <div
              className={'d-md-block justify-content-end w-sm-100'}
              style={{ minWidth: '10rem', maxWidth: "14rem" }}>
              <PrimaryButtonForm
                bgcolor="PURPLE_GRAY_500"
                onClick={handelResetPassword}
                minWidth="100%">
                <span className={'me-2'}>Reset password</span>
              </PrimaryButtonForm>
              <PrimaryButtonForm
                bgcolor="PURPLE_GRAY_500"
                style={{ marginTop: 5 }}
                onClick={handelResetPin}
                minWidth="100%">
                <span className={'me-2'}>Reset Pin</span>
              </PrimaryButtonForm>
              <PrimaryButtonForm
                bgcolor="PURPLE_GRAY_500"
                style={{ marginTop: 5 }}
                onClick={handleForceLogOut}
                minWidth="100%">
                <span className={'me-2'}>Force Logout</span>
              </PrimaryButtonForm>


              <PrimaryButtonForm
                bgcolor="PURPLE_GRAY_500"
                style={{ marginTop: 5 }}
                onClick={handleResetLoginAattempts}
                minWidth="100%">
                <span className={'me-2'}>Reset Login Attempts</span>
              </PrimaryButtonForm>
              <PrimaryButtonForm
                bgcolor="PURPLE_GRAY_500"
                style={{ marginTop: 5 }}
                onClick={handleResetPasswordExpiryDays}
                minWidth="100%">
                <span className={'me-2'}>Reset Pwd. Expiry Days</span>
              </PrimaryButtonForm>
            </div>
          </div>
          <div className={'mt-5 mt-md-3'}>
            <DropdownSearchable
              placeHolder={'Role'}
              options={roles}
              selectedOption={values.role_id || ''}
              onChange={value =>
                dropDownHandleSelect({ role_id: value ? value : null })
              }
            />
          </div>
          {/* //checked box */}
          <div className='mt-2'>
            <DropdownSearchable
              disabled={true}
              isMulti={true}
              placeHolder={'Linked to Client'}
              options={importantList}
              selectedOptionsProps={importantList}
              selectedOption={importantList}

            />



            <div className='d-flex' style={{ marginTop: '0.5rem' }}>
              <InputLabelTop
                onClick={copyToClipboardWeb}
                ref={textAreaRef}
                readOnly={true}
                type="text"
                id="url_for_web"
                label={'Web Url'}
                {...useInput('url_for_web', '')}
              />
              {copySuccessWeb &&
                <IconContext.Provider
                  value={{ color: 'green', size: '2.1rem' }}>
                  <span style={{ marginTop: '5px', marginLeft: "5px" }}> copied!</span>
                  <RiCheckboxCircleFill />
                </IconContext.Provider>}
            </div>
            <div className='d-flex'>
              <InputLabelTop
                onClick={copyToClipboardMobile}
                //ref={textAreaRef}
                readOnly={true}
                type="text"
                id="url_for_mobile"
                label={'Mobile Url'}
                {...useInput('url_for_mobile', '')}
              />
              {copySuccessMobile &&
                <IconContext.Provider
                  value={{ color: 'green', size: '2.1rem' }}>
                  <span style={{ marginTop: '5px', marginLeft: "5px" }}> copied!</span>
                  <RiCheckboxCircleFill />
                </IconContext.Provider>}
            </div>
          </div>

          <div className={'mt-5 pt-1 mt-md-3'}>
            <div className="form-check ms-1">
              <input type="checkbox" disabled={true} className="form-check-input " id="exampleCheck1"
                checked={defaultChecks}
              />
              <label className="form-check-label text-secindary " for="exampleCheck1" > <small>Office</small></label>
            </div>
          </div>

          <div className={'d-flex justify-content-end mt-5'}>
            <PrimaryButtonForm minWidth="10rem" onClick={handelSaveClick}>
              <span className={'me-2'}>{textStatus}</span>
              {!loadingSave ? (
                buttonIcon
              ) : (
                <span
                  className="spinner-border spinner-border-sm me-1"
                  role="status"
                />
              )}
            </PrimaryButtonForm>
          </div>
        </div>

        <ModalInformation
          type={'error'}
          title={'Incorrect Email.'}
          onCancelTitle={'Cancel'}
          icon={ERROR_OUTLINE}
          hideOnCancel={false}
          onCancel={handleErrorModelCancel}
          showModal={showErrorMessage}>
          <MessageOptaPlanner className={'mt-4 mb-3'}>
            <div className={'warningMessage ps-3 pe-3'}>
              {errorMessage.message ||
                'The email for password reset is' + ' incorrect'}
            </div>
          </MessageOptaPlanner>
        </ModalInformation>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default EditModal;
