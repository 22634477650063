import React, { useState, useEffect, useContext } from 'react';
import {
  HeaderVisits,
  ContainerTabStyled,
  LinkTabStyled,
  LinkTab,
} from 'shared/styles/constants/tagsStyles';
import Table from 'components/SharedComponents/Table';
import { AppContext, BranchItemsContext } from "context"
import Pagination from 'components/SharedComponents/Pagination';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { getBellNotificationss, readNotification } from 'utils/api/SettingsApi'
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import queryString from 'query-string';
import { useForm } from 'hooks/FormHook';
import { useHistory } from 'react-router-dom';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import {
  Row,
  Col,
} from 'components/SharedComponents/Table/styles';
import { SMALL_COL, EX_VERY_LARGE_COL, } from 'shared/styles/constants/columns';
import moment from 'moment/moment';
import { Link, Redirect, Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import NotificationSettings from './NotificationSettings';
import { BELLNOTIFICATIONTABLE, NOTIFICATIONS_SETTING_URL, PAGE_404 } from 'utils/constants/routes';
import { NOTIFICATION_SETTING, NOTIFICATION_TAB, SELF_WEB_CREATE_PERMISSION, SELF_WEB_READ_PERMISSION, SELF_WEB_UPDATE_PERMISSION, WEB_CREATE_PERMISSION, WEB_DELETE_PERMISSION, WEB_PERMISSION, WEB_READ_PERMISSION, WEB_UPDATE_PERMISSION } from 'utils/constants/permissions';

const headerColumns = [
  // {
  //   label: 1,
  //   checkbox:true,
  //   name: 'checkbox',
  //   status: 0,
  //   className: 'grow-mobile',
  //   max_width: EX_SMALL_COL4,
  // },

  {
    label: 'Notification',
    name: 'user_notification_created_at',
    status: 2,
    className: 'grow-mobile    ',
    max_width: SMALL_COL,
  },

  {
    label: 'Description',
    name: 'message',
    status: 0,
    className: 'grow-mobile  ',
    max_width: EX_VERY_LARGE_COL,
  },

];


const NotificationTable = (props) => {
  const { values, setValues } = useForm({ ordering: '-user_notification_created_at' });
  const [currentPage, setCurrentPage] = useState(1);
  const [bellNotifications, setBellNotifications] = useState([])
  const [pages, setPages] = useState(0);
  const { branchData } = useContext(BranchItemsContext);
  const history = useHistory();
  const userID = localStorage.getItem("user_id");
  const [loadingModal, setLoadingModal] = useState(true);
  const [readNotificationTab, setReadNotificationTab] = useState(true);
  const [readNotificationSettingTab, setReadNotificationSettingTab] = useState(true);
  const { contextChoices: choices, contextPermissions, contextIsAdmin, contextIsSuperAdmin } = useContext(AppContext);



  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({ ...values })
  }, [])

  useEffect(() => {
    const roles = contextPermissions;

    let rolePermissions = {};

    if (contextIsAdmin == true || contextIsSuperAdmin == true) {
      setReadNotificationTab(true);
      setReadNotificationSettingTab(true);
    }
    else {
      if (roles.length > 0) {
        roles.forEach(item => {
          rolePermissions[item.section.name] = {};
          rolePermissions[item.section.name][WEB_PERMISSION] = {};
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] = item?.[WEB_READ_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
          ] = item?.[SELF_WEB_READ_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] = item?.[WEB_CREATE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            SELF_WEB_CREATE_PERMISSION
          ] = item?.[SELF_WEB_CREATE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ] = item?.[WEB_DELETE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] = item?.[WEB_UPDATE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            SELF_WEB_UPDATE_PERMISSION
          ] = item?.[SELF_WEB_UPDATE_PERMISSION];
        }

        );
        if (rolePermissions[NOTIFICATION_TAB]) {
          setReadNotificationTab(
            rolePermissions[NOTIFICATION_TAB][WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );

        }
        if (rolePermissions[NOTIFICATION_SETTING]) {
          setReadNotificationSettingTab(
            rolePermissions[NOTIFICATION_SETTING][WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );
        }
        if (!(rolePermissions[NOTIFICATION_TAB][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ])) {
          history.push('/dashboard-notification-settings')
        }
      }

    }

  }, [contextPermissions, contextIsSuperAdmin, contextIsAdmin])


  useEffect(() => {
    loadBellNotifications()
  }, [
    values.ordering,
    props.location.search,
    values.limitperpage
  ])

  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };

  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = offset * values.limitperpage; return {
      offset,
    };
  };


  const loadBellNotifications = () => {
    const pageToSearch = getPageToSearchParam(props.location.search);
    getBellNotificationss({ ...branchData, ...values, ...pageToSearch }, values.limitperpage, userID)
      .then(response => {
        if (response) {
          setLoadingModal(false);
          setBellNotifications(response.results || []);
          setPages(Math.ceil(response.count / values.limitperpage));
        }

      })
  }


  const dropDownHandleSelectPagination = (item, namesection) => {
    const urlParams = queryString.parse(props.location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: "/dashboard-notification",
      search: stringified,
    });
    console.log(item[namesection])
    values.limitperpage = item[namesection]
    setValues({ ...values });
  }


  const handleNotificationStaus = async (index, id, notified) => {
    const response = await readNotification([id], !notified);
    if (response.message ==
      "User Notification is updated") {
      loadBellNotifications();
    }
  }


  const handleAllRead = async () => {
    let allreadIds = values.checkbox_notified_list.filter(data => data.is_notified === false).map(obj => obj.id);
    const response = await readNotification(allreadIds, true);
    if (response.message ==
      "User Notification is updated") {
      resetAllCheck();
      loadBellNotifications();
    }
  };


  const handleAllUnread = async () => {
    let allunreadIds = values.checkbox_notified_list.filter(data => data.is_notified === true).map(obj => obj.id);
    const response = await readNotification(allunreadIds, false);
    if (response.message ==
      "User Notification is updated") {
      resetAllCheck();
      loadBellNotifications();
    }
  };

  const handleAllCheck = () => {
    let checkAll = true;

    const alertCheckBoxExists = (id) => {
      return values.checkbox_notified_list.some(function (el) {
        return el.checkbox_rowId === id;
      });
    }

    if (values.checkbox_notified_list && values.checkbox_notified_list.length > 0) {
      bellNotifications.map((notifiedData) => {
        if (!alertCheckBoxExists(notifiedData.id)) {
          checkAll = false
        }
      })
    } else {
      checkAll = false;
    }

    return checkAll;
  };

  const handleAllSelectChange = (e) => {
    if (!values.checkbox_notified_list) {
      values.checkbox_notified_list = [];
    }

    if (e.target.checked) {
      bellNotifications.map(notifiedData => {
        values.checkbox_notified_list.push({ ...notifiedData, checkbox_rowId: notifiedData.id })
      })
    } else {
      values.checkbox_notified_list = [];
    }
    setValues({ ...values })
  };

  const handleCheck = (notifiedData) => {
    let check = false;
    if (values.checkbox_notified_list) {
      values.checkbox_notified_list.map(data => {
        if (data.checkbox_rowId == notifiedData.id) {
          check = true;
        }
      })
    }
    return check;
  };

  const handleSelectChange = (e, notifedData, index) => {

    if (!values.checkbox_notified_list) {
      values.checkbox_notified_list = [];
    }

    if (e.target.checked) {
      values.checkbox_notified_list.push({ ...notifedData, checkbox_rowId: notifedData.id })
    } else {
      values.checkbox_notified_list.map((data, index) => {
        if (data.checkbox_rowId == notifedData.id) {
          values.checkbox_notified_list.splice(index, 1);
        }
      })
    }
    setValues({ ...values })

  };


  const resetAllCheck = () => {
    values.checkbox_notified_list = [];
    setValues({ ...values });
  }

  console.log(history.location.pathname)
  const reviewLocation = (url) => {
    console.log("url", url, "history", history.location.pathname)
    return url === history.location.pathname
  }


  return (
    <div >
      <div className="mt-2 mb-1 p-2 p-sm-3 p-md-4" >
        {/* <Title>Notifications</Title>  */}
        <ContainerTabStyled className="text-center mb-4">

          <div className={'d-flex firstRow'}>
            {readNotificationTab &&
              <LinkTabStyled active={reviewLocation(BELLNOTIFICATIONTABLE)}>
                <Link to={BELLNOTIFICATIONTABLE} className={'styledLink'}>
                  <LinkTab active={reviewLocation(BELLNOTIFICATIONTABLE)}>
                    Notifications
                  </LinkTab>
                </Link>
              </LinkTabStyled>}

            {readNotificationSettingTab &&
              <LinkTabStyled active={reviewLocation(NOTIFICATIONS_SETTING_URL)} >
                <Link to={NOTIFICATIONS_SETTING_URL} className={'styledLink'} >
                  <LinkTab active={reviewLocation(NOTIFICATIONS_SETTING_URL)} >
                    Notifications Settings (Beta)
                  </LinkTab>
                </Link>
              </LinkTabStyled>}
          </div>
        </ContainerTabStyled>
      </div>

      <Switch>
        <Route exact path={BELLNOTIFICATIONTABLE}>
          {readNotificationTab ?
            <React.Fragment>
              <div className="d-flex justify-content-end   ">
                {values.checkbox_notified_list && values.checkbox_notified_list.length > 0 ? (
                  <div class="dropdown me-5  ">
                    <PrimaryButtonForm minWidth="6rem" class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span className={'me-2'}>Action</span>
                    </PrimaryButtonForm>
                    <div class="dropdown-menu " aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item" onClick={handleAllRead} style={{ cursor: "pointer" }}>Mark all read</a>
                      <a class="dropdown-item" onClick={handleAllUnread} style={{ cursor: "pointer" }}>Mark all unread</a>
                    </div>
                  </div>

                ) : null}
              </div>
              <HeaderVisits></HeaderVisits>
              {(bellNotifications.length < 1 && !loadingModal) ? (
                <h6 className={`text-danger mt-5 `} style={{ textAlign: 'center' }}>No Notifications !!</h6>
              ) : (null)
              }

              {bellNotifications && bellNotifications.length > 0 ? (

                <Table
                  headerColumns={headerColumns} callBackQuerySort={setQuerySort} headerPadding={false} handleAllCheck={handleAllCheck} handleAllSelectChange={handleAllSelectChange}
                  noEditOption >
                  <div >
                    {bellNotifications && bellNotifications.map((list, index) => {
                      return (
                        <div key={index}   >
                          <Row bgColor >
                            {/* <Col
           max_width={EX_SMALL_COL4}
            Shrink={false}
           NoFlexGrow={true}
           reducePadding
          Capitalize
           Center
          >
              <input 
              class="form-check-input" 
             type="checkbox" 
             checked={handleCheck(list)} 
             id="flexCheckDefault"
           onChange={(e) => {handleSelectChange(e, list, index) }} />
            </Col> */}
                            <Col
                              max_width={SMALL_COL}
                              Capitalize
                              Center
                            >
                              {moment(list?.created_at?.split('T')[0], 'YYYY-MM-DD').format('YYYY-MM-DD') + ' ' + '' + '' +
                                moment(list?.created_at?.split('T')[1], 'HH:mm').format('HH:mm')}
                            </Col>

                            <Col
                              max_width={EX_VERY_LARGE_COL}
                              Capitalize
                              Center
                              overlap={true}
                            >
                              {list?.message}
                            </Col>
                            {/* <Col className="sm-hidden" Center Icon >                   
            {list.is_notified ?(
        <div  data-toggle="tooltip" data-placement="top" title="Mark as unread" onClick={()=>{handleNotificationStaus(index,list.id,list.is_notified)}}> <IconWrapper> <AiOutlineCheck  color={'green'}   /> </IconWrapper></div>

        ) :  

        ( 
          <div  data-toggle="tooltip" data-placement="top" title="Mark as read" onClick={()=>{handleNotificationStaus(index,list.id,list.is_notified)}}><IconWrapper>  <FaTimes color={'red'} /></IconWrapper></div>
           )}      
                          
            </Col> */}
                          </Row>
                          <div className="mb-1" />
                        </div>
                      );
                    })}

                    <div className="mt-3">
                      <Pagination 
                      totalPages={pages} 
                      currentPage={currentPage}
                       values={values} 
                       dropDownHandleSelect={dropDownHandleSelectPagination} 
                       number={PAGE_SIZE_RENDER}
                        dataLength={bellNotifications.length}
                      />
                    </div>

                  </div>
                </Table>

              ) :
                (<ComponentDynamic loading={loadingModal} > </ComponentDynamic>)}

            </React.Fragment>
            :
            <div className="w-100 mt-3 d-flex justify-content-center">
              <div className="text-danger">No permissions given for notification access !</div>
            </div>
          }
        </Route>

        <Route exact path={NOTIFICATIONS_SETTING_URL}>
          {readNotificationSettingTab ?
            <NotificationSettings />
            :
            <div className="w-100 mt-3 d-flex justify-content-center">
              <div className="text-danger">No permissions given for notification access !</div>
            </div>
          }
        </Route>
        <Redirect
          to={{
            pathname: PAGE_404,
            state: { referrer: '/' },
          }}
        />
      </Switch>


    </div>
  )
}

export default NotificationTable