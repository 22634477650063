import React from 'react'
import { LoadingContainer } from '../ModalDynamic/styles'
import Spinner from 'components/SharedComponents/Spinner'
import { Toast } from 'primereact/toast'
import { DropdownTypesContainer, Title } from 'shared/styles/constants/tagsStyles'
import DateSelector from 'components/SharedComponents/DateSelector'
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter'
import { useState } from 'react'
import { useRef } from 'react'
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination'
import { useForm } from 'hooks/FormHook'
import { useContext } from 'react'
import { AppContext, BranchItemsContext } from 'context'
import { useEffect } from 'react'
import { insights_choices } from 'utils/choiceConstant'
import { getInsightsRecords, setMarkReview } from 'utils/api/DashboardApi'
import { PrimaryButtonForm } from 'shared/styles/buttons'
import moment from 'moment'
import { WHITE } from 'shared/styles/constants/colors'
import './insight.css'
import RenderTimeLine from './TimeLine'
import { NoDataFoundMessage } from '../Location/AddLocationModal/styles'
import InfiniteScroll from 'react-infinite-scroll-component'
import { NoPermissionContainer } from '../Team/AddModal/styles'
import { ERROR, InvalidOrEmptyValues, SAVE, SAVED, SERVER_ERROR } from 'utils/constants/messages'
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons'
import { useModal } from 'hooks/ModalHook'
import MarkReviewPopup from './MarkReviewPopup'

function DashboardInsights({ dashboardPermission }) {
    const toast = useRef(null)
    console.log("DashboardInsights")

    const { values, setValues, useInput, errors, setCheckRequires } = useForm({
        limitperpage: PAGE_SIZE_RENDER,
        start_date: moment().format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD")
    });

    const { handleGetChoices, contextChoices } = useContext(AppContext)
    const { branchData } = useContext(BranchItemsContext);

    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState({
        show: false,
        msg: ""
    });
    const [listOfInsites, setListOfInsights] = useState([])
    const [clientsFilter, setClientFilter] = useState([])
    const [employeeFilter, setEmployeeFilter] = useState([])
    const [branchFilter, setBranchFilter] = useState([]);
    const [page, setPage] = useState(0)
    const [isNext, setIsNext] = useState(true);
    const startDateHook = useInput('start_date')
    const endDateHook = useInput('end_date')

    const [seletedCard, setSeletedCard] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);
    const [textStatus, setTextStatus] = useState(SAVE);
    const [buttonIcon, setButtonIcon] = useState(FLOPPY);
    const [loadingSave, setLoadingSave] = useState(false);

    const {
        setValue: setMarkReviewModal,
        value: markReview,
        fullBind: fullBindMarkReview,
    } = useModal(false);

    useEffect(() => {
        handleGetChoices(insights_choices)
    }, [])


    useEffect(() => {
        getListOfInsights()
    }, [contextChoices, branchData])



    const getbackGrouncolor = (recordsOf) => {
        switch (recordsOf) {
            case "Med":
                return '#d3fcf5'
            case "Clock-in":
                return '#ffd5d3'
            case "Clock-out":
                return '#c6ebd5'
            case "Task":
                return '#e9e2a7'
            case "Triage":
                return '#a7d1e9'
            case "Note":
                return '#dab6c5'
            default:
                return '#9f9f9f'
        }
    }



    const getListOfInsights = async (c_page = false, index = null) => {
        if (loading) return;
        setLoading(true)
        setErrMsg({
            show: false,
            msg: ""
        })
        if (new Date(values.start_date) > new Date(values.end_date)) {
            setErrMsg({
                show: true,
                msg: "Invalid date: start date must be less than end date!"
            })
            setLoading(false)
            return;
        }
        getInsightsRecords({ ...values, ...branchData }, c_page ? 0 : page, index,)
            .then(response => {
                let data = response?.data?.map(val => {
                    const dateOfRecord = val.dateOfRecord;
                    return ({
                        id: val.id,
                        title: val.title,
                        backgroundColor: getbackGrouncolor(val.recordsOf),
                        textColor: WHITE,
                        constraint: {
                            resourceIds: [val.id],
                        },
                        dateOfRecord: dateOfRecord,
                        description: val.description,
                        recordsOf: val.recordsOf,
                        client: val.client,
                        employee: val.employee && val.employee,
                        status: val.status && val.status,
                        review: val.review || false
                    });
                });
                let newData = [...data]
                setListOfInsights(prev => [...prev, ...newData.flat()])
                setPage(prev => prev + 20)
                response?.data.length > 0 ? setIsNext(true) : setIsNext(false);
                setLoading(false)
            })
            .catch(error => {
                console.error("Error: fetching insights api", error)
                setErrMsg({
                    show: true,
                    msg: "Something went wrong. Please try again later."
                })
                setLoading(false)
            })
    }

    const handleSerch = () => {
        setPage(0)
        setListOfInsights([])
        getListOfInsights(true)
    }


    const clientsFilterFun = (item) => {
        setClientFilter([...item])
    }

    const dropDownHandleSelect = item => {
        setValues({ ...values, ...item });
    };

    const checkReviewStatus =  id => {
        let status = id && contextChoices?.medication_review_status?.find(item => item?.id == id)
        && contextChoices?.medication_review_status?.find(item => item?.id == id)
        return status?.name == 'Reviewed'
      }
    

    const handleMarkReview = async () => {

        if (errors.length) {
            setCheckRequires(true)
            setErrorMessage(InvalidOrEmptyValues)
            setShowError(true)
            return;
        }
        setLoadingSave(true)
        if (!seletedCard.recordsOf || !seletedCard.id) {
            toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: `Required data missing!`,
            });
            return;
        }
        const body = {
            "reviewStatus": values.review_status,
            "reviewComment": values.review_comment || "",
            "medication": (seletedCard.recordsOf === "Med"),
            "VisitCareNotes": (seletedCard.recordsOf === "Note")
        }
        try {
            const res = await setMarkReview(body, seletedCard.id)
            if (res.status == "FAIL") {
                throw res
            }
            listOfInsites[seletedCard.index] = { ...seletedCard, review: checkReviewStatus(values.review_status) }
            setListOfInsights([...listOfInsites])
            onSuccess(res)

        } catch (error) {
            onError(error)
            console.error("ERROR: error updating mark review", error)
        }
    }

    const onSuccess = (res) => {
        setMarkReviewModal(false)
        setTextStatus(SAVED)
        setButtonIcon(CHECK)
        setLoadingSave(false)
        toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: `${res.message}`,
        });
        values.review_status = "";
        values.review_comment = "";
        setValues({ ...values })
        setTextStatus(SAVE)
        setButtonIcon(FLOPPY)
    }
    const onError = (error) => {
        setTextStatus(ERROR)
        setButtonIcon(CROSS)
        setLoadingSave(false)
        setShowError(true);
        setErrorMessage(error.message || SERVER_ERROR)
    }
    console.log(values, seletedCard, "listOfInsites")

    return (
        <>{dashboardPermission ? (
            <div className='insights'>

                <Toast ref={toast} position="top-right" />
                <>
                    <div className="filter-container ">
                        <Title className=''>Insights</Title>
                        <div className='mt-2 d-flex flex-wrap'>
                            <DropdownTypesContainer className="me-2">
                                <DateSelector
                                    typeFilter={true}
                                    labelTop="Date From"
                                    value={values.start_date ? values.start_date : ''}
                                    {...startDateHook}
                                />
                            </DropdownTypesContainer>
                            <DropdownTypesContainer className="me-2">
                                <DateSelector
                                    typeFilter={true}
                                    labelTop="Date To"
                                    value={values.end_date ? values.end_date : ''}
                                    {...endDateHook}
                                />
                            </DropdownTypesContainer>
                            <DropdownTypesContainer className="me-2">
                                <NoSelectAllDropdownFilter
                                    darkPlaceHolder={true}
                                    items={contextChoices &&
                                        contextChoices.client_listing &&
                                        contextChoices.client_listing.map(y => {
                                            return { id: y && y.id, name: y && y.full_name };
                                        }) || []}
                                    setItemsSelected={items => {
                                        dropDownHandleSelect({ client: items });
                                    }}
                                    itemsSelected={clientsFilter}
                                    setSelectedFilter={clientsFilterFun}
                                    placeHolder={'Clients'}
                                    isObject
                                    allOption
                                />
                            </DropdownTypesContainer>
                            <DropdownTypesContainer className="me-2">
                                <NoSelectAllDropdownFilter
                                    darkPlaceHolder={true}
                                    items={contextChoices &&
                                        contextChoices.employee_listing &&
                                        contextChoices.employee_listing.map(y => {
                                            return { id: y && y.id, name: y && y.full_name };
                                        }) || []}
                                    setItemsSelected={items => {
                                        dropDownHandleSelect({ employees: items });
                                    }}
                                    itemsSelected={employeeFilter}
                                    setSelectedFilter={setEmployeeFilter}
                                    placeHolder={'Employees'}
                                    isObject
                                    allOption
                                />
                            </DropdownTypesContainer>
                            <DropdownTypesContainer className="me-2">
                                <NoSelectAllDropdownFilter
                                    darkPlaceHolder={true}
                                    items={contextChoices &&
                                        contextChoices.branch &&
                                        contextChoices.branch.map(y => {
                                            return { id: y && y.id, name: y && y.name };
                                        }) || []}
                                    setItemsSelected={items => {
                                        dropDownHandleSelect({ branch: items });
                                    }}
                                    itemsSelected={branchFilter}
                                    setSelectedFilter={setBranchFilter}
                                    placeHolder={'Branches'}
                                    isObject
                                    allOption
                                />
                            </DropdownTypesContainer>
                            <PrimaryButtonForm
                                minWidth="6rem"
                                onClick={() => handleSerch()}
                            >
                                Search
                            </PrimaryButtonForm>
                        </div>
                    </div>
                    {
                        <div className='pie_chart_box p-4' style={{ minHeight: '75vh' }}>
                            {errMsg.show && (
                                <NoDataFoundMessage top={"14%"} style={{ marginTop: "100px" }} >
                                    <h1 style={{ fontSize: '1rem' }}>{errMsg.msg}</h1>
                                </NoDataFoundMessage>
                            )}
                            {!errMsg.show && listOfInsites && (
                                listOfInsites.length > 0 ? (
                                    <InfiniteScroll
                                        dataLength={listOfInsites.length}
                                        next={getListOfInsights}
                                        hasMore={isNext}
                                        loader={
                                            <LoadingContainer>
                                                <Spinner />
                                            </LoadingContainer>}
                                    >
                                        {listOfInsites.map((item, index) => {
                                            return (
                                                <RenderTimeLine
                                                    toast={toast}
                                                    choices={contextChoices}
                                                    item={item}
                                                    index={index}
                                                    listOfInsites={listOfInsites}
                                                    setListOfInsights={setListOfInsights}
                                                    setSeletedCard={setSeletedCard}
                                                    setMarkReview={setMarkReviewModal}
                                                />
                                            )
                                        })}
                                    </ InfiniteScroll>
                                ) :
                                    (<NoDataFoundMessage top={"14%"} style={{ marginTop: "100px" }} >
                                        <h1 style={{ fontSize: '1rem' }}>No data found</h1>
                                    </NoDataFoundMessage>)
                            )
                            }
                        </div>
                    }
                </>

            </div>
        ) : (
            <NoPermissionContainer>
                You don't have permission to access the information.
            </NoPermissionContainer>
        )
        }

            {markReview &&
                <MarkReviewPopup
                    fullBind={fullBindMarkReview}
                    useInput={useInput}
                    values={values}
                    setValues={setValues}
                    choices={contextChoices}
                    editMode={true}
                    handleMarkReview={handleMarkReview}
                    errorMessage={errorMessage}
                    showError={showError}
                    dropDownHandleSelect={dropDownHandleSelect}
                    title={
                        seletedCard?.recordsOf == "Med"
                            ? "Medication"
                            : seletedCard?.recordsOf == "Note"
                                ? "Care Notes"
                                : ""}
                    textStatus={textStatus}
                    loadingSave={loadingSave}
                    buttonIcon={buttonIcon}
                />}
        </>)
}

export default DashboardInsights