import React, { useState, useEffect, Fragment } from 'react';
import {
  TitleSection,
  FlexEqual,
  ContainerResponsive,
  ErrorMessage,
  NoPermissionContainer,
} from './styles';

import { useForm } from 'hooks/FormHook';
import { PrimaryButtonForm, UploadInput } from 'shared/styles/buttons';
import MapComponent from 'components/SharedComponents/Map/MapComponent'
import { pickFile } from 'utils/files';
import Geocode from 'react-geocode'
import {
  FlexGrid3Container,
  FlexGrid3Item,
} from 'shared/styles/constants/tagsStyles';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import _ from 'lodash';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import { CHECK, CROSS, ERROR_OUTLINE, FLOPPY } from 'utils/constants/icons';
import {
  InvalidOrEmptyValues,
  ERROR,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import {
  setEmployee,
  getEmployee,
  uploadEmployeePhoto,
  shouldUpdateEmpStatus
} from 'utils/api/EmployeeApi';
import { useHistory } from 'react-router';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import {
  WEB_CREATE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
  EMPLOYEE_PERSONAL_BASIC_DETAILS,
  EMPLOYEE_PERSONAL_ADDRESS,
  EMPLOYEE_PERSONAL_CONTACT,
  EMPLOYEE_PERSONAL_INVITE_USER,
  SELF_WEB_CREATE_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
  SELF_WEB_READ_PERMISSION, EMPLOYEE_ABSENCE_OVERVIEW
} from '../../../../utils/constants/permissions';
import BasicInformation from 'components/DashboardComponents/Team/AddModal/TeamComponents/BasicInformation';
import PersonalAddress from 'components/DashboardComponents/Team/AddModal/TeamComponents/PersonalAddress';
import PersonalContact from 'components/DashboardComponents/Team/AddModal/TeamComponents/PersonalContact';
import { useContext } from 'react';
import { AppContext } from 'context';
import { personal_detail_emp_choices } from 'utils/choiceConstant';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { useModal } from 'hooks/ModalHook';
import moment from 'moment';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import ActiveVisitsAndShiftsLists from './ActiveVisitsAndShiftDetails/ActiveVisitsAndShiftsLists';
const PersonalDetails = ({
  choices,
  clientList,
  successCallback,
  subSectionPersonalDetailsCreate,
  subSectionPersonalDetailsUpdate,
  createPermissionPersonalDetails,
  updatePermissionPersonalDetails,
  readPermissionPersonalDetails,
  selectedId,
  setEmpId,
  setRowData,
  filterAdmin,
  filterSuperAdmin,
  contextSubscription,
  rightPermission,
  setPhotoById,
  userEmployee,
  props,
  handleChangeChoices,
}) => {

  const toast = useRef(null)
  const [employeePhoto, setEmployeePhoto] = useState(null);
  const [makeUser, setMakeUser] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingSave, setLoadingSave] = useState(false);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [modalLoading, setModalLoading] = useState(true)
  const [warningText, setWarningText] = useState('null');
  const [dateStatus, setDateStatus] = useState('');
  const [personalDetailsBasicInfoCreate, setPersonalDetailsBasicInfoCreate] = useState(false);
  const [personalDetailsBasicInfoUpdate, setPersonalDetailsBasicInfoUpdate] = useState(false);
  const [personalDetailsBasicInfoRead, setPersonalDetailsBasicInfoRead] = useState(false);
  const [personalDetailsAddressCreate, setPersonalDetailsAddressCreate] = useState(false);
  const [personalDetailsAddressUpdate, setPersonalDetailsAddressUpdate] = useState(false);
  const [personalDetailsAddressRead, setPersonalDetailsAddressRead] = useState(false);
  const [personalDetailsContactRead, setPersonalDetailsContactRead] = useState(false);
  const [personalDetailsContactCreate, setPersonalDetailsContactCreate] = useState(false);
  const [personalDetailsContactUpdate, setPersonalDetailsContactUpdate] = useState(false);
  const [personalDetailsInviteUserRead, setPersonalDetailsInviteUserRead] = useState(false);
  const [personalDetailsInviteUserCreate, setPersonalDetailsInviteUserCreate] = useState(false);
  const [personalDetailsInviteUserUpdate, setPersonalDetailsInviteUserUpdate] = useState(false);
  const [VisitDetails, setVisitsDetails] = useState({
    regularShifts: [],
    regularVisits: [],
    shifts: [],
    visits: [],
  })
  const { setValue: setModalWarningValue, bind: bindModalWarning } = useModal(false);
  const { setValue: setModalValueVisitDetails, value: modalValueVisitDetails, fullBind: fullBindVisitDetails } = useModal(false);

  const { values, setValues, useInput, isValid, errors, setFormErrors, setCheckRequires } =
    useForm({ client_restriction: false });
  Geocode.setApiKey(process.env.REACT_APP_GOOGLEAPI_KEY);
  Geocode.setLanguage("en");
  const { handleGetChoices, contextChoicesMap } = useContext(AppContext)
  const myLocation = useLocation()
  useEffect(() => {
    if (myLocation.pathname.split("/").includes("personalDetails")) {
      handleGetChoices(personal_detail_emp_choices)
    }
  }, [myLocation.pathname])
  useEffect(() => {
    if (filterSuperAdmin) {
      setPersonalDetailsBasicInfoCreate(true);
      setPersonalDetailsBasicInfoUpdate(true);
      setPersonalDetailsBasicInfoRead(true);
      setPersonalDetailsAddressCreate(true)
      setPersonalDetailsAddressUpdate(true)
      setPersonalDetailsAddressRead(true)
      setPersonalDetailsContactRead(true)
      setPersonalDetailsContactUpdate(true)
      setPersonalDetailsContactCreate(true)
      setPersonalDetailsInviteUserRead(true)
      setPersonalDetailsInviteUserCreate(true)
      setPersonalDetailsInviteUserUpdate(true)
    } else if (filterAdmin) {
      setPersonalDetailsBasicInfoCreate(true);
      setPersonalDetailsBasicInfoUpdate(true);
      setPersonalDetailsBasicInfoRead(contextSubscription?.[EMPLOYEE_PERSONAL_BASIC_DETAILS]?.[WEB_PERMISSION]);
      setPersonalDetailsAddressCreate(true)
      setPersonalDetailsAddressUpdate(true)
      setPersonalDetailsAddressRead(contextSubscription?.[EMPLOYEE_PERSONAL_ADDRESS]?.[WEB_PERMISSION])
      setPersonalDetailsContactRead(contextSubscription?.[EMPLOYEE_PERSONAL_CONTACT]?.[WEB_PERMISSION])
      setPersonalDetailsContactUpdate(true)
      setPersonalDetailsContactCreate(true)
      setPersonalDetailsInviteUserRead(contextSubscription?.[EMPLOYEE_PERSONAL_INVITE_USER]?.[WEB_PERMISSION])
      setPersonalDetailsInviteUserCreate(true)
      setPersonalDetailsInviteUserUpdate(true)
    }
    else {
      if (rightPermission[EMPLOYEE_PERSONAL_BASIC_DETAILS] !== undefined) {
        setPersonalDetailsBasicInfoCreate(
          rightPermission[EMPLOYEE_PERSONAL_BASIC_DETAILS][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[EMPLOYEE_PERSONAL_BASIC_DETAILS][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedId))
        );
        setPersonalDetailsBasicInfoUpdate(
          rightPermission[EMPLOYEE_PERSONAL_BASIC_DETAILS][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[EMPLOYEE_PERSONAL_BASIC_DETAILS][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedId))
        );
        setPersonalDetailsBasicInfoRead(contextSubscription?.[EMPLOYEE_PERSONAL_BASIC_DETAILS]?.[WEB_PERMISSION] &&
          (rightPermission[EMPLOYEE_PERSONAL_BASIC_DETAILS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rightPermission[EMPLOYEE_PERSONAL_BASIC_DETAILS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] && (userEmployee == selectedId)))
        );
      }

      if (rightPermission[EMPLOYEE_PERSONAL_ADDRESS] !== undefined) {

        setPersonalDetailsAddressCreate(
          rightPermission[EMPLOYEE_PERSONAL_ADDRESS][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[EMPLOYEE_PERSONAL_ADDRESS][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedId))
        );
        setPersonalDetailsAddressUpdate(
          rightPermission[EMPLOYEE_PERSONAL_ADDRESS][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[EMPLOYEE_PERSONAL_ADDRESS][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedId))
        );
        setPersonalDetailsAddressRead(contextSubscription?.[EMPLOYEE_PERSONAL_ADDRESS]?.[WEB_PERMISSION] &&
          (rightPermission[EMPLOYEE_PERSONAL_ADDRESS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rightPermission[EMPLOYEE_PERSONAL_ADDRESS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] && (userEmployee == selectedId)))
        );
      }

      if (rightPermission[EMPLOYEE_PERSONAL_CONTACT] !== undefined) {
        setPersonalDetailsContactRead(contextSubscription?.[EMPLOYEE_PERSONAL_CONTACT]?.[WEB_PERMISSION] &&
          (rightPermission[EMPLOYEE_PERSONAL_CONTACT][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rightPermission[EMPLOYEE_PERSONAL_CONTACT][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] && (userEmployee == selectedId)))
        );
        setPersonalDetailsContactUpdate(
          rightPermission[EMPLOYEE_PERSONAL_CONTACT][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[EMPLOYEE_PERSONAL_CONTACT][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedId))
        );
        setPersonalDetailsContactCreate(
          rightPermission[EMPLOYEE_PERSONAL_CONTACT][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[EMPLOYEE_PERSONAL_CONTACT][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedId))
        );
      }
      if (rightPermission[EMPLOYEE_PERSONAL_INVITE_USER]) {
        setPersonalDetailsInviteUserRead(contextSubscription?.[EMPLOYEE_PERSONAL_INVITE_USER]?.[WEB_PERMISSION] &&
          (rightPermission[EMPLOYEE_PERSONAL_INVITE_USER][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rightPermission[EMPLOYEE_PERSONAL_INVITE_USER][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] && (userEmployee == selectedId)))
        )
        setPersonalDetailsInviteUserCreate(
          rightPermission[EMPLOYEE_PERSONAL_INVITE_USER][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[EMPLOYEE_PERSONAL_INVITE_USER][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedId))
        )
        setPersonalDetailsInviteUserUpdate(
          rightPermission[EMPLOYEE_PERSONAL_INVITE_USER][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[EMPLOYEE_PERSONAL_INVITE_USER][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedId))
        )
      }
    }

  }, [filterAdmin, filterSuperAdmin, rightPermission, contextSubscription])

  useEffect(() => {
    if (selectedId) {
      loadEmployee();
    } else {
      setModalLoading(false);
    }
  }, [selectedId])

  useEffect(() => {
    setRowData({
      photo: employeePhoto,
      first_name: values.first_name,
      last_name: values.last_name,
      full_name: values.full_name,
      date_of_birth: values.date_of_birth
    })
  }, [employeePhoto,
    values.first_name,
    values.last_name,
    values.full_name,
    values.date_of_birth])

  const history = useHistory()


  const handlePickFile = async (evt, section) => {

    const result = await pickFile(evt);
    console.log(result, 555333)
    switch (section) {
      case 'photo':
        setEmployeePhoto(result.file);
        break;
      case 'nok':
        // setNokPhoto(result.file);
        break;
      default:
    }
  };
  const mapClickHandler = (lat, lon) => {
    Geocode.fromLatLng(lat, lon).then(
      (response) => {
        // const address = response.results[0].formatted_address;

        let tempAddress = _.cloneDeep(response.results[0].address_components)
        if (!values.address) {
          values.address = {}
        }
        values.address.latitude = lat
        values.address.longitude = lon
        response.results[0].address_components.forEach((element, index) => {
          if (element.types.find(value => value == 'postal_code')) {
            values.address.zip_code = element.long_name
            //  tempAddress.splice(index,1)
          }
          else if (element.types.find(value => value == 'country')) {
            values.address.country = element.long_name
            // tempAddress.splice(index,1)
          }
          else if (element.types.find(value => value == 'administrative_area_level_1')) {
            values.address.state = element.long_name
            // tempAddress.splice(index,1)
          }
          else if (element.types.find(value => value == 'administrative_area_level_2' || value == 'locality')) {
            values.address.city = element.long_name
            // tempAddress.splice(index,1)
          }

        });
        let lineAddress = ""
        tempAddress.forEach(ele => {
          if (ele.types.includes('postal_code') || ele.types.includes('country') || ele.types.includes('administrative_area_level_1')
            || ele.types.includes('administrative_area_level_2') || ele.types.includes('locality')
          ) {
            lineAddress = lineAddress
          } else {
            lineAddress += ele.long_name + " "
          }

        })
        values.address.line_1 = lineAddress
        setValues({ ...values })
      },
      (error) => {
        console.error(error);
      }
    );
  }
  const dropDownHandleSelect = (item, name) => {
    //recursive objects merge
    if ((name == 'branch') || (name == 'accessBranch')) {
      if (!values.employeeJobDetailsBranchAccess) {
        values.employeeJobDetailsBranchAccess = {};
      }
      if (name == 'branch') {
        values.employeeJobDetailsBranchAccess.branch = item.branch;
      } else {
        values.employeeJobDetailsBranchAccess.accessBranch = item.accessBranch.map((item) => (item.value));
      }
      setValues({ ...values });
    } else if (name == 'privacy') {
      values.employee_privacy = item.employee_privacy;
      setValues({ ...values });
    } else {
      const result = _.merge(values, item);
      setValues({ ...result });
    }

  };

  const loadEmployee = async () => {
    const response = await getEmployee(selectedId);
    if (response.role) {
      response.role_id = response.role
    }
    if (response.employee_privacy) {
      response.employee_privacy = response.employee_privacy.map((item) => ({ value: item.privacy, get_id: item.id, label: item.name }))
    }
    setValues({ ...response })
    setModalLoading(false);
  }

  const shouldUpdateEmployeeStatus = async () => {
    if (errors.length > 0) {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }
    setCheckRequires(false);
    setShowError(false);
    let status_name = values?.employee_status && contextChoicesMap?.employee_status && contextChoicesMap?.employee_status[values?.employee_status]?.name
    if ((status_name && (status_name != 'Active') && (status_name != 'Active - Probation')) || values?.employeeJobDetailsBranchAccess?.employment_end_date) {

      const body = {
        last_working_date: values?.employeeJobDetailsBranchAccess?.employment_end_date
          ? moment(values?.employeeJobDetailsBranchAccess?.employment_end_date).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
        employee: values.id
      }
      try {
        const res = await shouldUpdateEmpStatus(body)
      // Previous code
        if (res.status) {
          checkClientStatus()
        } else {
          const message = `You have active shifts, Please check shifts end date, it must be lesser than ${values?.employeeJobDetailsBranchAccess?.employment_end_date ? values?.employeeJobDetailsBranchAccess?.employment_end_date : moment().format("YYYY-MM-DD")}`
          toast.current.show({
            severity: 'error',
            summary: 'Failed',
            detail: `${message}`,
          });
          loadEmployee()
        }


        // New code with pop-up

        // if(res.regularShifts.length === 0 
        //   && res.regularVisits.length === 0 
        //   && res.shifts.length === 0 
        //    && res.visits.length === 0 ){
        //   checkClientStatus()
        // } else {
        //   setVisitsDetails({
        //     regularShifts: res.regularShifts,
        //     regularVisits: res.regularVisits,
        //     shifts: res.shifts,
        //     visits: res.visits
        //   })
        //   setModalValueVisitDetails(true)
        // }

      } catch (error) {
        onError(error)
      }
    } else {
      handleSubmit()
    }
  }



  const checkClientStatus = () => {
    let status_name = values?.employee_status && contextChoicesMap?.employee_status[values?.employee_status]?.name
    if ((status_name != 'Active') && (status_name != 'Active - Probation') && status_name) {
      let date = values.employeeJobDetailsBranchAccess.employment_end_date ? moment(values.employeeJobDetailsBranchAccess.employment_end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
      setModalWarningValue(true)
      setWarningText(`${status_name}`)
      setDateStatus(date)
      return;
    } else {
      if (values.employeeEndDate) {
        setModalWarningValue(true)
        setWarningText(`${status_name}`)
        debugger
        return;
      }
    }
    setModalWarningValue(false);
    handleSubmit()
    debugger
  }

  const handleSubmit = (evt) => {
    // evt.preventDefault();
    if (loadingSave) {
      return;
    }

    let body = {
      ...values,
    };
    setModalWarningValue(false);
    if (values.contact) {
      body.contact.contact_method = values.contact.contact_method ? values.contact.contact_method : '';
    }

    //to delete personal email if empty 
    // if(body.contact.email_personal == ''){
    //   delete body.contact.email_personal;
    // }


    if (values.make_user) {
      if (body.role_id) {
        body.role = body.role_id
      }
      if (body.employee_privacy) {
        body.employee_privacy = body.employee_privacy.map((val) => {
          let obj = {
            privacy: val.value
          };
          if (val.get_id) {
            obj.id = val.get_id
          }
          return obj
        })
      }
    } else {
      delete body.role
      delete body.role_id
      debugger
    }
    body.client_restriction = (values?.client_restriction && values.make_user)
    delete body.branch_name
    delete body.photo;
    // if ((!isValid || errors.length > 0)) {
    if (errors.length > 0) {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }
    setLoadingSave(true);
    setCheckRequires(false);
    setShowError(false);
    let value = [];
    console.log(body, "body")
    setEmployee(body)
      .then(response => {
        if (response.statusCode === 400) {
          // console.log( response.message,"445877")
          if (response.message) {
            value = response.message
          } else {
            value = Object.keys(response)[0] + "-" + Object.values(response)[0]
          }
          throw value;
        }

        setShowError(false);
        onSuccess(response);
      })
      .catch(err => {
        let error = "";
        if (typeof err == 'object') {
          error = Array.isArray(err) && err[`role_id`][0]
        } else if (err.message) {
          error = err.message
        } else {
          error = err
        }
        console.log(error)
        onError(error)
      });
  };

  const savePhoto = (empId, branch_access) => {
    if (employeePhoto) {
      let body = {
        photo: employeePhoto,
        employeeJobDetailsBranchAccess: branch_access
      }
      uploadEmployeePhoto(body, empId)
    }
  }
  // console.log(values, "values")
  const onSuccess = async (response) => {
    let employeeId = response.id;

    if (props.location.pathname === '/team/add/personalDetails') {
      props.history.push(`/team/edit/${employeeId}/personalDetails`)
    }
    if (employeeId) {
      handleChangeChoices();

    }
    setValues({
      ...values,
      id: employeeId,
      is_active: response.is_active,
    });
    setEmpId(employeeId)
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    savePhoto(employeeId, response.employeeJobDetailsBranchAccess)
    setTextStatus(SAVED);
    // loadEmployee()
  };

  const onError = err => {
    // {"status":"False","message":{"role_id":["This field may not be null."]}}


    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setCheckRequires(true);
    setErrorMessage(err);
    setShowError(true);
    // loadEmployee()
  };
  const handelMakeUser = () => {
    setValues({ ...values, role: null, role_id: null, make_user: !values.make_user });

    // setMakeUser(prevState => !prevState);
  };
  const handelClientAccess = () => {
    setValues({ ...values, client_restriction: !values.client_restriction });
  }
  // console.log(errorMessage, "errorMessage")
  const roleInputHook = useInput('role_id', values.make_user ? 'isRequired' : "")
  return (
    <>
      <Toast ref={toast} />
      <ComponentDynamic
        loading={modalLoading}
      >
        {(selectedId ? readPermissionPersonalDetails : createPermissionPersonalDetails) ?
          <>
            {(selectedId ? personalDetailsBasicInfoRead : personalDetailsBasicInfoCreate)
              &&
              <BasicInformation
                values={values}
                setPhotoById={setPhotoById}
                useInput={useInput}
                employeePhoto={employeePhoto}
                choices={choices}
                dropDownHandleSelect={dropDownHandleSelect}
                handlePickFile={handlePickFile}
                clientList={clientList}
                readOnly={selectedId ? !personalDetailsBasicInfoUpdate : !personalDetailsBasicInfoCreate
                }
                contextChoicesMap={contextChoicesMap}
              />
            }


            <ContainerResponsive className="d-flex justify-content-between mt-3">
              {(selectedId ? personalDetailsAddressRead : personalDetailsAddressCreate)
                &&

                <PersonalAddress
                  useInput={useInput}
                  values={values}
                  setValues={setValues}
                  readOnly={selectedId ? !personalDetailsAddressRead : !personalDetailsAddressCreate
                  }
                />


              }
              {(selectedId ? personalDetailsAddressRead : personalDetailsAddressCreate)
                &&
                <FlexEqual className="mt-2">
                  <MapComponent
                    containerElement={<div style={{ height: `300px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    latitude={values && values.address && values.address.latitude}
                    longitude={values && values.address && values.address.longitude}
                    mapClickHandler={mapClickHandler}
                  />
                </FlexEqual>
              }
            </ContainerResponsive>

            {(selectedId ? personalDetailsContactRead : personalDetailsContactCreate)
              &&
              <PersonalContact
                useInput={useInput}
                values={values}
                choices={choices}
                dropDownHandleSelect={dropDownHandleSelect}
                readOnly={selectedId ? !personalDetailsContactRead : !personalDetailsContactCreate
                }
                inviteUserStatus={values.make_user}
              />
            }



            <ContainerResponsive className="d-flex me-4 mt-3">
              {(selectedId ? personalDetailsInviteUserRead : personalDetailsInviteUserCreate) && (
                <ToggleButton
                  label="Invite as User"
                  // useInput={useInput('make_user')}
                  id="make_user"
                  onClick={(selectedId ? personalDetailsContactUpdate : personalDetailsInviteUserCreate) && handelMakeUser}
                  status={values.make_user}
                />
              )}
              {values.make_user &&
                <div className='ms-4'>
                  <ToggleButton
                    label="Restricted Client Access"
                    id="client_restriction"
                    onClick={() => handelClientAccess()}
                    status={values.client_restriction}
                  />
                </div>}
            </ContainerResponsive>

            {(selectedId ? personalDetailsInviteUserRead : personalDetailsInviteUserCreate) && values.make_user && (
              <ContainerResponsive className="w-75">
                <FlexEqual className="me-md-2 me-sm-0">
                  <TitleSection>Roles And Permission</TitleSection>
                  <div className="d-flex flex-column mt-2">
                    <div className="w-100 d-flex mb-2">
                      <div className="w-100 ps-1 pe-0 pe-md-1">
                        <DropdownSearchable
                          placeHolder={'Role'}
                          options={choices ? choices?.roles?.filter(role => role.user_type === 'employee') : []}
                          selectedOption={values.role_id}
                          required={true}
                          {...roleInputHook}
                          onChange={value =>
                            dropDownHandleSelect({
                              role_id: value ? value : null,
                            })
                          }
                        />
                      </div>
                      <div className="w-100 ps-1 pe-0 pe-md-1">
                        <DropdownSearchable
                          isMulti={true}
                          placeHolder={'Privacy Type'}
                          options={choices ? choices.alert_privacy : []}
                          selectedOptionsProps={values.employee_privacy}
                          selectedOption={values.employee_privacy}
                          onChange={value =>
                            dropDownHandleSelect({ employee_privacy: value ? value : null }, 'privacy')
                          }
                        />
                      </div>
                      {choices && choices.roles && choices.roles.find(role => role.id === values.role_id) && choices.roles.find(role => role.id === values.role_id).is_staff &&
                        <span
                          className={'ms-2 mt-1'}
                          style={{ color: "green", fontStyle: 'italic' }}
                        >OFFICE</span>
                      }

                    </div>
                  </div>
                </FlexEqual>
              </ContainerResponsive>
            )}

            <div
              className={
                'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
              }>
              <div className={'mb-3'}>
                {showError ? (
                  <ErrorMessage>
                    <label className={'mt-2'}>{errorMessage}</label>
                  </ErrorMessage>
                ) : (
                  <Fragment />
                )}
              </div>

              <div
                className={
                  'd-flex justify-content-center justify-content-md-end'
                }>
                <div className={'ms-0 ms-md-3 me-2'}>
                  <PrimaryButtonForm
                    bgcolor="PURPLE_GRAY_500"
                    minWidth="6rem"
                    className={'me-2'}
                    onClick={() => {
                      // setModalValue()
                      history.push('/team')
                    }}>
                    <span className={'me-2'}>{'Cancel'}</span>
                  </PrimaryButtonForm>
                  {(selectedId ? updatePermissionPersonalDetails : true) && (
                    <PrimaryButtonForm
                      bgcolor="PURPLE_GRAY_500"
                      minWidth="6rem"
                      onClick={shouldUpdateEmployeeStatus}>
                      <span className={'me-2'}>{textStatus}</span>
                      {!loadingSave ? (
                        buttonIcon
                      ) : (
                        <span
                          className="spinner-border spinner-border-sm me-1"
                          role="status"
                        />
                      )}
                    </PrimaryButtonForm>
                  )}
                </div>
              </div>
            </div>
          </>
          :
          <NoPermissionContainer>
            You don't have permission to {selectedId ? 'read' : 'create'} the information.
          </NoPermissionContainer>
        }
        <ModalDecision
          type="warning"
          title="Warning"
          body={
            (warningText == 'Active') ? 'Shifts wont be created after employee end date, if you want to view the changes on scheduler please remove employee end date. ' : `Status for employee is set ${warningText}, Visit will be deleted from ${dateStatus}.`
          }
          subBody={'Do you still want to continue?'}
          onOk={() => handleSubmit()}
          onCancel={() => {
            setModalWarningValue(false);
          }}
          okTitle={'Confirm'}
          {...bindModalWarning}
        />

        {modalValueVisitDetails &&
          <ActiveVisitsAndShiftsLists
             type={'warning'}
             icon={ERROR_OUTLINE}
             fullBind={fullBindVisitDetails}
             VisitDetails={VisitDetails}
             toast={toast}
             choices={choices}
             shouldUpdateEmployeeStatus={shouldUpdateEmployeeStatus}
          />}
      </ComponentDynamic>

    </>
  );
}

export default PersonalDetails;
