import React, { useState, useEffect } from 'react';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import Table from 'components/SharedComponents/Table';
import { headerColumns } from './headerColumns';
import {
  P_W_Zero,
  EX_SMALL_COL4,
  EX_SMALL_COL,
  SMALL_COL,
  LARGE_COL,
  EX_LARGE_COL,
} from 'shared/styles/constants/columns';
import {
  Row,
  Col,
  DayPill,
  ColsGrouper,
  LockIconWrapper,

} from 'components/SharedComponents/Table/styles';
import _ from 'lodash'
import moment from 'moment'
import { FaLock } from "react-icons/fa";
const CalendarListView = ({
  events,
  handleSelectChange,
  setCheckedRows,
  checkedRows,
  setCheckedValue,
  checkedValue,
  choices,
  eventClickHandler,
  displayMenu
}) => {
  const [loadingModal, setLoadingModal] = useState(false)
  const [sortedEvents, setSortedEvents] = useState([])



  useEffect(() => {
    setCheckedRows([])
    console.log(events, 44588)
    let actualEvents = events.filter(ev => (!ev.waittime && !ev.traveltime && ev.type !=='regularShift') && ev.id)
    let tempEvents = actualEvents.sort((a, b) => {
      let da = new Date(a.start),
        db = new Date(b.start);
      return da - db;
    })
    setSortedEvents(tempEvents)
  }, [events])

  const handleAllCheck = () => {
    let checkAll = true;

    const visitExists = (id) => {
      return checkedRows.some(function (el) {
        return el === id;
      });
    }
    if (checkedRows && checkedRows.length > 0) {
      events.map((visit) => {
        if (!visitExists(visit.id)) {
          checkAll = false
        }
      })
    } else {
      checkAll = false;
    }
    return checkAll;
  }

  const handleAllSelectChange = (e) => {
    setCheckedValue(!checkedValue)
    //  let tempChecked=[]
    // if(e.target.checked){
    //   events.forEach(ev=>{
    //     tempChecked.push(ev.id)
    //   })
    // }

    // setCheckedRows([...tempChecked])

    if (!checkedRows) {
      checkedRows = [];
    }
    if (e.target.checked) {
      events.map((visit) => {
        checkedRows.push(visit.id)
      })
    } else {
      events.map((visit) => {
        checkedRows.map((item, index) => {
          if (item === visit.id) {
            checkedRows.splice(index, 1);
          }
        })
      })
    }
    setCheckedRows([...checkedRows])
  }
  const capitalizeText = (text) => text ? text.charAt(0).toUpperCase() + text.slice(1) : "";
  console.log(sortedEvents.filter(e => e.is_lock),"sortedEvents")
  return (
    <div className='mt-5'>
      <ComponentDynamic
        loading={loadingModal}>
        <Table headerColumns={headerColumns && headerColumns} handleAllCheck={handleAllCheck} handleAllSelectChange={handleAllSelectChange} headerPadding={false} noEditOption={true} noNeed={false} numberOfIcons={1}>
          <div >
            {
              sortedEvents && sortedEvents.map((visit, index) => {
                return (
                  <div onContextMenu={(e)=>{
                    e.preventDefault();
                    displayMenu(e, {
                      event: {
                        _def: {
                          publicId: visit.id
                        }
                      }
                    })
                  }}>
                    <Row bgColor Shadow>
                      <ColsGrouper
                        className="d-flex"
                      >
                        <Col
                          max_width={EX_SMALL_COL4}
                          Shrink={false}
                          NoFlexGrow={true}
                          reducePadding
                          Capitalize
                          Center

                        >
                          <input class="form-check-input" type="checkbox"
                            checked={checkedRows.includes(visit.id)}
                            id="flexCheckDefault"
                            onChange={(e) => { handleSelectChange(e, visit) }}
                          />

                        </Col>
                        <ColsGrouper
                          className="d-flex"
                          onClick={() => {
                            eventClickHandler({ id: visit.id })
                          }}>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >
                            {!visit.isLocation ? visit.client ? visit.client : '' : ""}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >
                            {visit.employee_name ?
                              visit.employee_name
                              : ''}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >
                            {visit.isLocation ? visit.client ? visit.client : '' : ""}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >
                            {visit.run_view_id ?
                              (choices.run_view && choices.run_view.find(run => run.id == visit.run_view_id) && choices.run_view.find(run => run.id == visit.run_view_id).name)
                              : ''}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >
                            {visit.service_category ? capitalizeText(visit.service_category) : ''}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >
                            {visit.start ? moment(visit.start).format('YYYY-MM-DD') : ''}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >

                            {
                              visit.end ? moment(visit.end).format('YYYY-MM-DD') : ''}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >
                            {visit.start ? moment(visit.start).format('HH:mm') : ''}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >
                            {visit.end ? moment(visit.end).format('HH:mm') : ''}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >
                            {visit.schedule_duration}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >
                            {visit.actual_visit_start_time ? moment(visit.actual_visit_start_time).format('HH:mm') : ''}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >
                            {visit.actual_visit_end_time ? moment(visit.actual_visit_end_time).format('HH:mm') : ''}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >
                            {visit.actual_visit_duration}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >
                            {visit.visit_status ?
                              visit.visit_status
                              : ''}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >
                            {visit.visit_status ?
                              visit.visit_status
                              : ''}
                          </Col>
                          <Col Center Shrink NoFlexGrow >
                       <LockIconWrapper>
                       {visit.is_lock &&  <FaLock /> }
                        </LockIconWrapper>
                      </Col>
                        </ColsGrouper>
                      </ColsGrouper>
                    </Row>
                  </div>
                )
              })
            }
          </div>
        </Table>
      </ComponentDynamic>
    </div>
  );
}

export default CalendarListView;
